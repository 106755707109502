import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

/** Payment methods enabled for this tenant account */
export enum AcceptedPaymentMethod {
  Amex = 'AMEX',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  Jcb = 'JCB',
  Mastercard = 'MASTERCARD',
  PaymentBank = 'PAYMENT_BANK',
  Unknown = 'UNKNOWN',
  Visa = 'VISA'
}

export type AccountBalances = {
  __typename?: 'AccountBalances';
  /**
   * Available balance in the given currency
   * @deprecated Use new field currentBalance
   */
  balance?: Maybe<Scalars['Float']>;
  /** Available balance in the given currency */
  currentBalance?: Maybe<Money>;
  /** Reserved balance */
  currentReserve?: Maybe<Money>;
  /**
   * Pending incoming balance
   * @deprecated Use new field incomingPendingBalance
   */
  incomingPending?: Maybe<Scalars['Float']>;
  /** Pending incoming balance */
  incomingPendingBalance?: Maybe<Money>;
  /**
   * Pending outgoing balance
   * @deprecated Use new field outgoingPendingBalance
   */
  outgoingPending?: Maybe<Scalars['Float']>;
  /** Pending outgoing balance */
  outgoingPendingBalance?: Maybe<Money>;
  /**
   * Reserved balance
   * @deprecated Use new field currentReserve
   */
  reserve?: Maybe<Scalars['Float']>;
  /** The timestamp when the balances where last updated */
  updatedTimestamp?: Maybe<Scalars['DateTime']>;
};

export type AccountOptions = {
  __typename?: 'AccountOptions';
  /** Payment options */
  payments?: Maybe<AccountPaymentOptions>;
};

export type AccountPaymentOptions = {
  __typename?: 'AccountPaymentOptions';
  /** Indicates whether payer needs to provide a reason for partial payment. */
  requirePartialPaymentReason?: Maybe<Scalars['Boolean']>;
};

/** Values for the state of updating payout account settings. */
export enum AccountPayoutsSettingsStatus {
  Processing = 'PROCESSING',
  ProcessingComplete = 'PROCESSING_COMPLETE',
  Unknown = 'UNKNOWN'
}

export type AccountPersonalVerification = {
  __typename?: 'AccountPersonalVerification';
  /** Documents showing address, either a passport, local ID card, or utility bill from a well-known utility company. */
  additionalDocuments: Array<Maybe<AccountVerificationDocument>>;
  /** Identifying documents for the person. */
  documents: Array<Maybe<AccountVerificationDocument>>;
  /** The state of verification for the person. */
  status: AccountPersonalVerificationStatus;
};

/** Values for the state of verification for a person. */
export enum AccountPersonalVerificationStatus {
  Pending = 'PENDING',
  Unspecified = 'UNSPECIFIED',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED'
}

export type AccountRelationship = {
  __typename?: 'AccountRelationship';
  /** How the person is involved with the account, controller, additional reps etc... */
  involvement?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['Boolean']>;
  percentOwnership?: Maybe<Scalars['Int']>;
  primaryAccountHolder?: Maybe<Scalars['Boolean']>;
  role: AppRole;
  title?: Maybe<Scalars['String']>;
};

export type AccountRequirements = {
  __typename?: 'AccountRequirements';
  currentDeadline?: Maybe<Scalars['DateTime']>;
  currentlyDue?: Maybe<Array<Scalars['String']>>;
  disabledReason?: Maybe<Scalars['String']>;
  /** Indicates which fields need to be updated. */
  errantFields?: Maybe<Scalars['JSONObject']>;
  errors?: Maybe<Array<Scalars['String']>>;
  eventuallyDue?: Maybe<Array<Scalars['String']>>;
  pastDue?: Maybe<Array<Scalars['String']>>;
  pendingVerification?: Maybe<Array<Scalars['String']>>;
};

export type AccountSettings = {
  __typename?: 'AccountSettings';
  /** Settings specific to the account’s payouts. */
  accountPayouts?: Maybe<PayoutSettings>;
  /** Settings specific to ACH payments on the account. */
  achPayments?: Maybe<PaymentSettings>;
  /** Settings specific to card charging on the account. */
  cardPayments?: Maybe<PaymentSettings>;
  /** The convenience fees. */
  convenienceFees?: Maybe<ConvenienceFees>;
  /** The custom email settings. */
  customEmailSettings?: Maybe<TenantAccountEmailSettings>;
  /** Set of feature flags enabled/disabled for the account. */
  features?: Maybe<Features>;
  /** The integration settings. */
  integrationSettings?: Maybe<IntegrationSettings>;
  /** Set of miscellaneous options on the account */
  options?: Maybe<AccountOptions>;
  /** The payment reminder settings. */
  paymentReminders?: Maybe<PaymentReminderSettings>;
  /** The capabilities supported by the tenant. */
  supportedCapabilities: TenantSupportedCapabilities;
  /** Supported payment methods enabled for the tenant. */
  supportedPaymentMethods?: Maybe<Array<PaymentMethodType>>;
};

export type AccountVerificationDocument = {
  __typename?: 'AccountVerificationDocument';
  customData?: Maybe<Scalars['JSONObject']>;
  documentId?: Maybe<Scalars['String']>;
  rejectReason?: Maybe<Scalars['String']>;
  status?: Maybe<DocumentStatus>;
  type?: Maybe<DocumentType>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  /** The country in which the address resides. This should be an ISO 3166-1 alpha-2 country code. */
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

/** Input for an address */
export type AddressInputType = {
  /** Address city. (Constraints: Maximum Length: 30) */
  city?: Maybe<Scalars['String']>;
  /** the country code in ISO format. (Constraints: Length: 2) */
  country: Scalars['String'];
  /** Addresss line 1. (Constraints: Maximum Length: 60) */
  line1?: Maybe<Scalars['String']>;
  /** Address line 2. (Constraints: Maximum Length: 60) */
  line2?: Maybe<Scalars['String']>;
  /** the address postal code. (Constraints: Maximum Length: 14) */
  postalCode: Scalars['String'];
  /** Address region. (Constraints: Maximum Length: 30) */
  region?: Maybe<Scalars['String']>;
};

/** The type of allocation for the customer allocation */
export enum AllocationType {
  Customer = 'CUSTOMER',
  Person = 'PERSON'
}

/** Input data for filtering by amount range */
export type AmountRange = {
  end?: Maybe<Scalars['Float']>;
  start: Scalars['Float'];
};

export enum AppRole {
  Admin = 'ADMIN',
  AnonymousPayer = 'ANONYMOUS_PAYER',
  ApiConsumer = 'API_CONSUMER',
  CheckoutApiConsumer = 'CHECKOUT_API_CONSUMER',
  Editor = 'EDITOR',
  JsLibrary = 'JS_LIBRARY',
  LimitedPayer = 'LIMITED_PAYER',
  LimitedUser = 'LIMITED_USER',
  Payer = 'PAYER',
  Reader = 'READER'
}

/** Aptean products. */
export enum ApteanProduct {
  Apprise = 'APPRISE',
  DynamicsFb = 'DYNAMICS_FB',
  M2M = 'M2M',
  Respond = 'RESPOND',
  Ross = 'ROSS',
  Unknown = 'UNKNOWN'
}

/** Input data for attaching a payment method */
export type AttachPaymentMethodInput = {
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Indicates if this payment method is the default for the payer. */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** The payment method id */
  paymentMethodId: Scalars['ID'];
  /** The id of the person, tenant or customer to attach the payment method to. */
  resourceId: Scalars['ID'];
  /** Indicates if this payment method is to be shared with merchant. */
  shareWithMerchant?: Maybe<Scalars['Boolean']>;
};

/** Status response for mutation for creating a payment method. */
export type AttachPaymentMethodStatus = {
  __typename?: 'AttachPaymentMethodStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  /** @deprecated This field is deprecated */
  resource?: Maybe<PaymentMethodOwnerResourceUnion>;
};

/** The type of bank account, checking or savings. */
export enum BankAccountType {
  Checking = 'CHECKING',
  Savings = 'SAVINGS',
  Unknown = 'UNKNOWN'
}

/** Input data for Bank */
export type BankDetailsInput = {
  /** last four digits of account number */
  lastFour?: Maybe<Scalars['String']>;
};

/** The business profile contains key details about the business, associated with the tenant account */
export type BusinessProfile = {
  __typename?: 'BusinessProfile';
  description?: Maybe<Scalars['String']>;
  /** High resolution logo image url */
  highResLogoUrl?: Maybe<Scalars['String']>;
  /** The url for the merchant logo */
  logoUrl?: Maybe<Scalars['String']>;
  /** The merchant classification code */
  mcc?: Maybe<Scalars['String']>;
  /** The name of the business profile */
  name: Scalars['String'];
  /** The support address */
  supportAddress?: Maybe<Address>;
  /** The email address of the merchants support team */
  supportEmail?: Maybe<Scalars['String']>;
  /** The phone number of the merchants support team */
  supportPhone?: Maybe<Phone>;
  /** The link to the merchants support website */
  supportUrl?: Maybe<Scalars['String']>;
  /** The url for the merchants website */
  url?: Maybe<Scalars['String']>;
};

/** The categories identifying the legal structure of the company or legal entity. */
export enum BusinessStructure {
  Corporation = 'CORPORATION',
  GovernmentAgency = 'GOVERNMENT_AGENCY',
  LimitedLiabilityCompany = 'LIMITED_LIABILITY_COMPANY',
  NonprofitCorporation = 'NONPROFIT_CORPORATION',
  Partnership = 'PARTNERSHIP',
  PersonalTrust = 'PERSONAL_TRUST',
  SoleProprietor = 'SOLE_PROPRIETOR',
  StatutoryTrust = 'STATUTORY_TRUST',
  UnincorporatedAssociation = 'UNINCORPORATED_ASSOCIATION',
  Unknown = 'UNKNOWN'
}

/** The type of business for the legal entity. */
export enum BusinessType {
  Company = 'COMPANY',
  GovtEntity = 'GOVT_ENTITY',
  Individual = 'INDIVIDUAL',
  NonProfit = 'NON_PROFIT',
  Unknown = 'UNKNOWN'
}

/** The calculated convenience fees. */
export type CalculatedConvenienceFees = {
  __typename?: 'CalculatedConvenienceFees';
  /** The convenience fees amount. */
  amount: Scalars['Int'];
  /** The convenience fees rate in basis points. Ex: '200' for 2% */
  rateBps: Scalars['Int'];
};

/** Input data for cancelling a pre-authorized payment. */
export type CancelPaymentInput = {
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  paymentId: Scalars['ID'];
  reason: Scalars['String'];
};

/** Status response for cancel payment mutation. */
export type CancelPaymentStatus = {
  __typename?: 'CancelPaymentStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  payment?: Maybe<Payment>;
};

/** The statuses for account capabilities. */
export enum CapabilityStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN'
}

/** Input data for payment capture amounts. */
export type CapturePaymentAmountsInput = {
  amount: Scalars['Int'];
  currency: CurrencyType;
};

/** Input data for manually capturing a payment. */
export type CapturePaymentInput = {
  /** The final capture amounts if less than the original authorized amounts. */
  amounts?: Maybe<CapturePaymentAmountsInput>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Customer PO number */
  customerPONumber?: Maybe<Scalars['String']>;
  /** Invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Level 2/Level 3 data. */
  l2l3Data?: Maybe<L2L3DataInput>;
  /** Order number */
  orderNumber?: Maybe<Scalars['String']>;
  paymentId: Scalars['ID'];
};

/** Status response for capturing payments mutation. */
export type CapturePaymentStatus = {
  __typename?: 'CapturePaymentStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  payment?: Maybe<Payment>;
};

/** The brand of credit card. */
export enum CardBrand {
  Amex = 'AMEX',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  Jcb = 'JCB',
  Mastercard = 'MASTERCARD',
  Unknown = 'UNKNOWN',
  Visa = 'VISA'
}

export type CardDetails = {
  __typename?: 'CardDetails';
  /** bin number */
  bin?: Maybe<Scalars['String']>;
  /** card type */
  type?: Maybe<CardType>;
};

/** Input data for card details */
export type CardDetailsInput = {
  /** bin number */
  bin?: Maybe<Scalars['String']>;
  /** card type */
  cardBrand?: Maybe<Scalars['String']>;
  /** card expiration month */
  expirationMonth?: Maybe<Scalars['Int']>;
  /** card expiration year */
  expirationYear?: Maybe<Scalars['Int']>;
  /** last four digits of card number */
  lastFour?: Maybe<Scalars['String']>;
};

export type CardInfo = {
  __typename?: 'CardInfo';
  /** The credit card brand name. */
  cardBrand?: Maybe<CardBrand>;
  /** The credit card expiration month. */
  expirationMonth?: Maybe<Scalars['Int']>;
  /** The credit card expiration year. */
  expirationYear?: Maybe<Scalars['Int']>;
  /** The last four digit of the credit card. */
  lastFour?: Maybe<Scalars['String']>;
};

/** The type of card i.e. Credit/Debit. */
export enum CardType {
  ChargeCard = 'CHARGE_CARD',
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  Unknown = 'UNKNOWN'
}

/** A checkout session record */
export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  /** The amount of the payment, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** The URL the customer will be directed to if they choose to cancel the payment. */
  cancelUrl: Scalars['String'];
  /** The URL the customer will be directed to complete the payment. */
  checkoutUrl?: Maybe<Scalars['String']>;
  /** The currency used for the payment */
  currency?: Maybe<CurrencyType>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Indicates whether to fail the mutation call and cancel the payment when it goes into the REVIEW state. (Default value: false) */
  failOnReview?: Maybe<Scalars['Boolean']>;
  /** The Id for the session */
  id: Scalars['String'];
  /** Indicates whether to immediately capture payment amount (default: true) */
  immediateCapture?: Maybe<Scalars['Boolean']>;
  /** The order details */
  orderDetails?: Maybe<OrderDetails>;
  /** The payer details */
  payerDetails?: Maybe<PayerDetails>;
  /** The payment information */
  paymentInfo?: Maybe<PaymentInfo>;
  /** Status of the checkout session */
  status?: Maybe<CheckoutSessionStatus>;
  /** The URL to which the customer will be directed if the payment is successful. */
  successUrl: Scalars['String'];
};

/** Input data for creating a session */
export type CheckoutSessionInput = {
  /** The id of the session */
  id: Scalars['String'];
};

/** Possible statuses for checkout session */
export enum CheckoutSessionStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  New = 'NEW'
}

/** The type of communication to send, sms or email */
export enum CommunicationType {
  Email = 'EMAIL',
  EmailAndSms = 'EMAIL_AND_SMS',
  None = 'NONE',
  Sms = 'SMS'
}

export type Company = {
  __typename?: 'Company';
  address?: Maybe<Address>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Phone>;
  structure?: Maybe<BusinessStructure>;
  taxIdProvided?: Maybe<Scalars['Boolean']>;
  verification?: Maybe<CompanyAccountVerification>;
};

/** A list of documentation used to verify the company account */
export type CompanyAccountVerification = {
  __typename?: 'CompanyAccountVerification';
  additionalDocuments: Array<Maybe<AccountVerificationDocument>>;
  documents: Array<Maybe<AccountVerificationDocument>>;
  /** Indicates if the account has got any KYC issues that would require a merchant to re-onboard. */
  kycIssue?: Maybe<Scalars['Boolean']>;
  /** Indicates if the account has got an issue with Social Security Number that would require a merchant to re-onboard. */
  ssnIssue?: Maybe<Scalars['Boolean']>;
  status: CompanyVerificationStatus;
  transitionStatus: TransitionStatus;
};

/** Values for the state of verification for a company */
export enum CompanyVerificationStatus {
  Pending = 'PENDING',
  Unspecified = 'UNSPECIFIED',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED'
}

/** Input data for completing Payment Method Request */
export type CompletePaymentMethodRequestInput = {
  /** The Payment Method Request ID. */
  id: Scalars['ID'];
  /** The Payment Method ID. */
  paymentMethodId: Scalars['ID'];
};

/** Status response for mutation for completing a payment method request. */
export type CompletePaymentMethodRequestStatus = {
  __typename?: 'CompletePaymentMethodRequestStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  /** The payment method request */
  paymentMethodRequest?: Maybe<PaymentMethodRequest>;
};

/** Input data for conceding a dispute */
export type ConcedeDisputeInput = {
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The explanation for changing the status. (Constraints: Maximum Length: 65535) */
  explanation: Scalars['String'];
  /** The dispute id */
  id: Scalars['ID'];
};

/** The convenience fees. */
export type ConvenienceFees = {
  __typename?: 'ConvenienceFees';
  /** The credit card rate in basis points. Ex: '200' for 2% */
  creditCardRateBps?: Maybe<Scalars['Int']>;
  /** The debit card rate in basis points. Ex: '200' for 2% */
  debitCardRateBps?: Maybe<Scalars['Int']>;
  /**
   * The maximum convenience fee could be set at a platform level (in basis points).
   * @deprecated Please use maxCreditConvenienceFee and maxDebitConvenienceFee
   */
  maxConvenienceFee?: Maybe<Scalars['Int']>;
  /** The maximum credit convenience fee could be set at a platform level (in basis points). */
  maxCreditConvenienceFee?: Maybe<Scalars['Int']>;
  /** The maximum debit convenience fee could be set at a platform level (in basis points). */
  maxDebitConvenienceFee?: Maybe<Scalars['Int']>;
};

/** Input data for converting a payfac payment method token */
export type ConvertPayfacPaymentMethodTokenInput = {
  /** Optional bank details */
  bank?: Maybe<BankDetailsInput>;
  /** Optional card details */
  card?: Maybe<CardDetailsInput>;
  /** Customer id to attach payment method with(only for merchant use). */
  customerId?: Maybe<Scalars['String']>;
  /** The payment method holder */
  holder: PaymentMethodHolderInput;
  /** Indicates if the resulting token will be kept on file as a recurring payment method */
  recurring?: Maybe<Scalars['Boolean']>;
  /** The payment method token */
  token: Scalars['String'];
  /** The payment method type */
  type: PaymentMethodType;
  /** This is set if the merchant manually entered the credit card number. "MOBILE" indicates that the virtual terminal transaction was completed in a Card Present environment. "WEB" indicates that the virtual terminal transaction was completed in a Card Not Present environment. */
  virtualTerminalMode?: Maybe<VirtualTerminalMode>;
};

/** Status response for mutation for converting a payment method token. */
export type ConvertPayfacPaymentMethodTokenStatus = {
  __typename?: 'ConvertPayfacPaymentMethodTokenStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  token?: Maybe<Token>;
};

/** Input data for converting a payment method claim */
export type ConvertPaymentMethodClaimInput = {
  /** The id of the payment method claim. */
  claimId: Scalars['String'];
};

/** Status response for mutation for creating a payment method claim. */
export type ConvertPaymentMethodClaimStatus = {
  __typename?: 'ConvertPaymentMethodClaimStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** The country code */
export enum CountryCode {
  Ca = 'CA',
  Us = 'US'
}

/** Input data for creating a session */
export type CreateCheckoutSessionInput = {
  /** The amount of the payment, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** The URL the customer will be directed to if they choose to cancel the payment. */
  cancelUrl: Scalars['String'];
  /** The currency used for the payment */
  currency: CurrencyType;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Indicates whether to fail the mutation call and cancel the payment when it goes into the REVIEW state. (Default value: false) */
  failOnReview?: Maybe<Scalars['Boolean']>;
  /** Indicates whether to immediately capture payment amount (default: true) */
  immediateCapture: Scalars['Boolean'];
  /** The order details */
  orderDetails: OrderDetailsInput;
  /** The payer details */
  payerDetails?: Maybe<PayerDetailsInput>;
  /** The URL to which the customer will be directed if the payment is successful. */
  successUrl: Scalars['String'];
};

/** Status response for mutation for creating a checkout session. */
export type CreateCheckoutSessionStatus = {
  __typename?: 'CreateCheckoutSessionStatus';
  /** The checkout session */
  checkoutSession?: Maybe<CheckoutSession>;
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** Input data for creating a payment */
export type CreatePaymentInput = {
  /** The amount to charge the payment method, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount: Scalars['Int'];
  /** The payment amount excluding convenience fees. This value is required when charging a convenience fee. */
  amountBeforeFees?: Maybe<Scalars['Int']>;
  /** The date to defer capturing the payment. Must be less than 7 days from payment creation date. */
  captureAt?: Maybe<Scalars['DateTime']>;
  /** The convenience fees amount */
  convenienceFee?: Maybe<Scalars['Int']>;
  /** The amount of credit to use (if this amount is not available payment will fail), the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  creditAmount?: Maybe<Scalars['Int']>;
  currency: CurrencyType;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The customer id, must be unique for the platform */
  customerId?: Maybe<Scalars['String']>;
  /** The customer number, must be unique for the tenant */
  customerNumber?: Maybe<Scalars['String']>;
  /** Customer PO number */
  customerPONumber?: Maybe<Scalars['String']>;
  /** The description of the payment */
  description?: Maybe<Scalars['String']>;
  /** Indicates whether to fail the mutation call and cancel the payment when it goes into the REVIEW state. (Default value: true) */
  failOnReview?: Maybe<Scalars['Boolean']>;
  /** Indicates whether to immediately capture payment amount */
  immediateCapture: Scalars['Boolean'];
  /** Invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Level 2/Level 3 data. */
  l2l3Data?: Maybe<L2L3DataInput>;
  /** Order number */
  orderNumber?: Maybe<Scalars['String']>;
  paymentMethodId: Scalars['ID'];
  paymentRequestAllocation?: Maybe<Array<PaymentRequestAllocationInput>>;
  paymentRequestId?: Maybe<Scalars['ID']>;
  /** Additional data for the payment that is used for risk analysis. */
  riskMetadata: RiskMetadataPaymentInput;
};

/** Input data for upserting a payment method claim */
export type CreatePaymentMethodClaimInput = {
  /** The expiry date of the payment method claim. */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** Indicates if this payment method claim is single use. */
  singleUse?: Maybe<Scalars['Boolean']>;
  /** The short lived or long lived payment method token. (Short lived will be converted to long lived). */
  token: Scalars['String'];
};

/** Status response for mutation for creating a payment method claim. */
export type CreatePaymentMethodClaimStatus = {
  __typename?: 'CreatePaymentMethodClaimStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  paymentMethodClaim?: Maybe<PaymentMethodClaim>;
};

/** Input data for upserting a payment method */
export type CreatePaymentMethodInput = {
  /** The id of the person or tenant to attach the payment method to. */
  attachToResourceId: Scalars['String'];
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Indicates if this payment method is the default for the payer. */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** Indicates if this payment method is shared with merchant. */
  shareWithMerchant?: Maybe<Scalars['Boolean']>;
  /** The payment method token */
  token: Scalars['String'];
};

/** Status response for mutation for creating a payment method. */
export type CreatePaymentMethodStatus = {
  __typename?: 'CreatePaymentMethodStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** Status response for creating payments mutation. */
export type CreatePaymentStatus = {
  __typename?: 'CreatePaymentStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  payment?: Maybe<Payment>;
};

/** Input data for creating a refund */
export type CreateRefundInput = {
  /** The amount of the refund, if partial. Otherwise null. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The payment id */
  paymentId: Scalars['String'];
  /** The payment request id */
  paymentRequestId?: Maybe<Scalars['ID']>;
  /** The reason for the refund. (Constraints: Minimum Length: 1, Maximum Length: 65535) */
  refundReason: Scalars['String'];
};

/** Status response for mutation for creating a refund. */
export type CreateRefundStatus = {
  __typename?: 'CreateRefundStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  refund?: Maybe<Refund>;
};

/** Input data for creating a report */
export type CreateReportInput = {
  /** The report end date */
  endDate: Scalars['DateTime'];
  /** The file format of the report */
  format: FileFormat;
  /** The file format of the report */
  reportType: ReportType;
  /** The report start date */
  startDate: Scalars['DateTime'];
};

/** Status response mutation for creating a report */
export type CreateReportStatus = {
  __typename?: 'CreateReportStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  report?: Maybe<Report>;
};

export type CreditCard = {
  __typename?: 'CreditCard';
  /** Brand of the credit card. Possible values include: amex, discover, diners, jcb, mastercard, visa. */
  cardBrand?: Maybe<CardBrand>;
  /** Personal details of the person who owns the card. */
  cardHolder?: Maybe<PaymentMethodHolder>;
  /** Indicates if the resulting token will be kept on file as a non-recurring payment method. */
  cardOnFile?: Maybe<Scalars['Boolean']>;
  /** Card Verification Value (CVV) of the card. */
  cvvProvided?: Maybe<Scalars['Boolean']>;
  /** Card details */
  details?: Maybe<CardDetails>;
  /** The display name for the card. */
  displayName?: Maybe<Scalars['String']>;
  /** The month the card expires. */
  expirationMonth?: Maybe<Scalars['Int']>;
  /** The year the card expires. */
  expirationYear?: Maybe<Scalars['Int']>;
  /** The last four digits of the card number. */
  lastFour?: Maybe<Scalars['String']>;
  /** Indicates if the resulting token will be kept on file as a recurring payment method. */
  recurring?: Maybe<Scalars['Boolean']>;
  /** This is set if the merchant manually entered the credit card number. "MOBILE" indicates that the virtual terminal transaction was completed in a Card Present environment. "WEB" indicates that the virtual terminal transaction was completed in a Card Not Present environment. */
  virtualTerminalMode?: Maybe<VirtualTerminalMode>;
};

/** A credit memo record */
export type CreditMemo = {
  __typename?: 'CreditMemo';
  /** The amount of the credit memo, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount: Scalars['Int'];
  /** The time the credit memo was closed */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** The time the credit memo was created */
  createdAt: Scalars['DateTime'];
  /** The creator that created the credit memo */
  createdBy: Scalars['String'];
  /** The credit usage history */
  creditUsageHistory?: Maybe<Array<CreditUsageHistory>>;
  /** The currency used for the credit memo */
  currency?: Maybe<CurrencyType>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The list of associated payers */
  customerAllocation: Array<CustomerAllocation>;
  /** The Id for the credit memo */
  id?: Maybe<Scalars['String']>;
  /** The time the credit memo was last redeemed */
  lastRedeemedAt?: Maybe<Scalars['DateTime']>;
  /** The time the credit memo was modified */
  modifiedAt?: Maybe<Scalars['DateTime']>;
  /** The user that modified the credit memo */
  modifiedBy?: Maybe<Scalars['String']>;
  /** The owner of the credit memo. */
  owner: Owner;
  /** The reason for the credit memo */
  reason?: Maybe<Scalars['String']>;
  /** The reference number of the credit memo */
  referenceNumber?: Maybe<Scalars['String']>;
  /** The remaining balance on the credit memo. */
  remainingBalance?: Maybe<Scalars['Int']>;
  /** The current status of the credit memo. */
  status?: Maybe<CreditMemoStatus>;
  /** The current reason for the status */
  statusReason?: Maybe<Scalars['String']>;
};

/** A credit memo allocation */
export type CreditMemoAllocation = {
  __typename?: 'CreditMemoAllocation';
  /** The amount of the credit memo, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** The time the credit memo was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The Id for the credit memo */
  creditMemoId: Scalars['String'];
  /** The reference number of the credit memo */
  referenceNumber?: Maybe<Scalars['String']>;
};

/** A list of credit memos associated with the payer. */
export type CreditMemoConnection = {
  __typename?: 'CreditMemoConnection';
  /** A list of edges. */
  edges: Array<Maybe<CreditMemoEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<CreditMemo>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** The credit memo field to query by */
export enum CreditMemoDateRangeType {
  CreditMemoCreated = 'CREDIT_MEMO_CREATED'
}

/**
 * An edge in a connection. Think of the edge as the connection between two nodes.
 *   Its tightly coupled to the filter and order by applied to the connection, any changes to the filter or order by will invalidate the edge
 */
export type CreditMemoEdge = {
  __typename?: 'CreditMemoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CreditMemo;
};

export type CreditMemoFeatures = {
  __typename?: 'CreditMemoFeatures';
  /** Allow merchants to display credit memos for payers. */
  enabled: Scalars['Boolean'];
};

/** Ways in which lists of credit memos can be ordered upon return */
export type CreditMemoOrder = {
  /** The direction in which to order credit memos by the specified field */
  direction: OrderDirection;
  /** The field in which to order credit memos */
  field: CreditMemoOrderField;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum CreditMemoOrderField {
  Timestamp = 'TIMESTAMP'
}

/** Possible statuses for the credit memo */
export enum CreditMemoStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
  Redeemed = 'REDEEMED'
}

/** Credit memo usage history of the payer. */
export type CreditMemoUsageConnection = {
  __typename?: 'CreditMemoUsageConnection';
  /** A list of edges. */
  edges: Array<Maybe<CreditUsageHistoryEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<CreditUsageHistory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** Ways in which lists of credit memo usage history can be ordered upon return */
export type CreditMemoUsageHistoryOrder = {
  /** The direction in which to order credit memo usage history by the specified field */
  direction: OrderDirection;
  /** The field in which to order credit memo usage history */
  field: CreditMemoUsageHistoryOrderField;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum CreditMemoUsageHistoryOrderField {
  Timestamp = 'TIMESTAMP'
}

/** A credit usage history */
export type CreditUsageHistory = {
  __typename?: 'CreditUsageHistory';
  /** The amount of the credit memo that was used, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount: Scalars['Int'];
  /** The datetime the credit was used */
  createdAt: Scalars['DateTime'];
  /** The reference number of the corresponding credit memo */
  creditMemoReferenceNumber?: Maybe<Scalars['String']>;
  /** The Id for the payment */
  paymentId?: Maybe<Scalars['String']>;
  /** The breakdown of how the credit memo was spread across payment requests */
  paymentRequestCreditAllocation?: Maybe<Array<PaymentRequestCreditAllocation>>;
  /** The remaining balance on the credit memo. */
  remainingBalance?: Maybe<Scalars['Int']>;
};

/**
 * An edge in a connection. Think of the edge as the connection between two nodes.
 *   Its tightly coupled to the filter and order by applied to the connection, any changes to the filter or order by will invalidate the edge
 */
export type CreditUsageHistoryEdge = {
  __typename?: 'CreditUsageHistoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CreditUsageHistory;
};

/** The currency of the transaction, usd or cad */
export enum CurrencyType {
  Cad = 'CAD',
  Unknown = 'UNKNOWN',
  Usd = 'USD'
}

export type CustomEmailSettingsFeatures = {
  __typename?: 'CustomEmailSettingsFeatures';
  /** Allow merchants to set custom email settings for payers. */
  enabled: Scalars['Boolean'];
};

export type Customer = {
  __typename?: 'Customer';
  /** The customer convenience fees. */
  convenienceFees?: Maybe<CustomerConvenienceFees>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  /** The customer number, must be unique for the tenant */
  customerNumber: Scalars['String'];
  /** The list of customer users. */
  customerUsers?: Maybe<Array<CustomerUser>>;
  /** Unique identifier for the object */
  id: Scalars['ID'];
  /** The customer’s name. */
  name?: Maybe<Scalars['String']>;
  /** The owner of the customer record. */
  owner: Owner;
  /** The customer’s payer account details. */
  payerProfile?: Maybe<CustomerPayerProfile>;
  /** The customer’s phone number. */
  phone?: Maybe<Phone>;
  /** The timestamp when the customer was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The user identifier who last updated the customer */
  updatedBy?: Maybe<Scalars['String']>;
};

/** A customer allocation */
export type CustomerAllocation = {
  __typename?: 'CustomerAllocation';
  /** The allocation type used for this credit memo */
  allocationType?: Maybe<AllocationType>;
  /** The Id of a customer. */
  customerId?: Maybe<Scalars['String']>;
  /** Unique customer’s number. */
  customerNumber?: Maybe<Scalars['String']>;
  /** The person’s email address. */
  email?: Maybe<Scalars['String']>;
};

/** A customer allocation */
export type CustomerAllocationInput = {
  /** The allocation type used for this credit memo */
  allocationType: AllocationType;
  /** Id of a customer */
  customerId?: Maybe<Scalars['String']>;
  /** Optional unique customer’s number */
  customerNumber?: Maybe<Scalars['String']>;
  /** The person’s email address. */
  email?: Maybe<Scalars['String']>;
};

/** A list of customers associated with the merchant. */
export type CustomerConnection = {
  __typename?: 'CustomerConnection';
  /** A list of edges. */
  edges: Array<Maybe<CustomerEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<Customer>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** The customer convenience fees. */
export type CustomerConvenienceFees = {
  __typename?: 'CustomerConvenienceFees';
  /** The credit card rate in basis points. Ex: '200' for 2% */
  creditCardRateBps?: Maybe<Scalars['Int']>;
  /** The debit card rate in basis points. Ex: '200' for 2% */
  debitCardRateBps?: Maybe<Scalars['Int']>;
  /**
   * The maximum convenience fee could be set at a platform level (in basis points).
   * @deprecated Please use maxCreditConvenienceFee and maxDebitConvenienceFee
   */
  maxConvenienceFee?: Maybe<Scalars['Int']>;
  /** The maximum credit convenience fee could be set at a platform level (in basis points). */
  maxCreditConvenienceFee?: Maybe<Scalars['Int']>;
  /** The maximum debit convenience fee could be set at a platform level (in basis points). */
  maxDebitConvenienceFee?: Maybe<Scalars['Int']>;
  /** Flag, whether to override tenant convenience fees for this customer. */
  overrideConvenienceFees: Scalars['Boolean'];
};

/** Input data for updating the customer convenience fees. */
export type CustomerConvenienceFeesInput = {
  /** The credit card rate in basis points. Ex: '200' for 2% */
  creditCardRateBps: Scalars['Int'];
  /** The debit card rate in basis points. Ex: '200' for 2% */
  debitCardRateBps: Scalars['Int'];
  /** Flag, whether to override tenant convenience fees for this customer. */
  overrideConvenienceFees: Scalars['Boolean'];
};

/** The customer field to query by. */
export enum CustomerDateRangeType {
  CustomerCreated = 'CUSTOMER_CREATED',
  CustomerUpdated = 'CUSTOMER_UPDATED'
}

/**
 * An edge in a connection. Think of the edge as the connection between two nodes.
 *   Its tightly coupled to the filter and order by applied to the connection, any changes to the filter or order by will invalidate the edge
 */
export type CustomerEdge = {
  __typename?: 'CustomerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Customer;
};

export type CustomerInfo = {
  __typename?: 'CustomerInfo';
  /** The customer ID */
  customerId?: Maybe<Scalars['String']>;
  /** The customer role */
  role?: Maybe<CustomerRole>;
  /** The owner tenant id of the customer record. */
  tenantId?: Maybe<Scalars['String']>;
};

export type CustomerOption = {
  __typename?: 'CustomerOption';
  /** The customer ID */
  customerId?: Maybe<Scalars['String']>;
  /** The name of the customer. */
  customerName?: Maybe<Scalars['String']>;
};

/** Ways in which lists of customers can be ordered upon return */
export type CustomerOrder = {
  /** The direction in which to order customers by the specified field */
  direction: OrderDirection;
  /** The field in which to order customers */
  field: CustomerOrderField;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum CustomerOrderField {
  CustomerName = 'CUSTOMER_NAME',
  Timestamp = 'TIMESTAMP'
}

export type CustomerPayerProfile = {
  __typename?: 'CustomerPayerProfile';
  /** List of payment methods. */
  paymentMethods?: Maybe<Array<CustomerPaymentMethod>>;
};

export type CustomerPaymentMethod = {
  __typename?: 'CustomerPaymentMethod';
  /** Indicates if this payment method is the default for the payer. */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** A payment method */
  paymentMethod?: Maybe<PaymentMethod>;
  /** Indicates if this payment method is shared with the merchant. */
  shareWithMerchant?: Maybe<Scalars['Boolean']>;
};

export enum CustomerRole {
  CustomerAdmin = 'CUSTOMER_ADMIN',
  CustomerManager = 'CUSTOMER_MANAGER',
  CustomerPayer = 'CUSTOMER_PAYER',
  CustomerReader = 'CUSTOMER_READER'
}

export type CustomerUser = {
  __typename?: 'CustomerUser';
  /** The customer's email address. */
  email?: Maybe<Scalars['String']>;
  /** The customer role */
  role?: Maybe<CustomerRole>;
};

export type CustomersFeatures = {
  __typename?: 'CustomersFeatures';
  /** Allow merchants to create customers. */
  enabled: Scalars['Boolean'];
};

/** The payment field to query by */
export enum DateRangeType {
  CompletedTimestamp = 'COMPLETED_TIMESTAMP',
  CreatedTimestamp = 'CREATED_TIMESTAMP'
}


/** Input data for deleting a payment method */
export type DeletePaymentMethodInput = {
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The payment method id */
  paymentMethodId: Scalars['ID'];
};

/** Status response for mutation for deleting a payment method. */
export type DeletePaymentMethodStatus = {
  __typename?: 'DeletePaymentMethodStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** Input data for deleting a person */
export type DeletePersonInput = {
  id: Scalars['ID'];
};

/** Status response mutation for deleting a person */
export type DeletePersonStatus = {
  __typename?: 'DeletePersonStatus';
  /** Whether the delete was successful, failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** Input data for detaching a payment method */
export type DetachPaymentMethodInput = {
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The payment method id */
  paymentMethodId: Scalars['ID'];
  /** The id of the person, tenant or customer to attach the payment method to. */
  resourceId: Scalars['ID'];
};

/** Status response for mutation for detaching a payment method. */
export type DetachPaymentMethodStatus = {
  __typename?: 'DetachPaymentMethodStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  /** @deprecated This field is deprecated */
  resource?: Maybe<PaymentMethodOwnerResourceUnion>;
};

/** The dispute is raised by the card / bank when the payee queries a payment that was made. */
export type Dispute = {
  __typename?: 'Dispute';
  /** The amount being disputed */
  amount?: Maybe<Scalars['Int']>;
  /** The type of card being used */
  cardBrand?: Maybe<CardBrand>;
  /** The explaination as to why the dispute has been conceded */
  concedeMessage?: Maybe<Scalars['String']>;
  /** The time the dispute was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The currency of the dispute */
  currency?: Maybe<CurrencyType>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The type of dispute, inquiry / charge back */
  disputeType?: Maybe<DisputeType>;
  /** The Id of the dispute */
  id?: Maybe<Scalars['ID']>;
  /** The owner of the payment dispute. */
  owner?: Maybe<Owner>;
  /** The payment related to the dispute */
  payment?: Maybe<Payment>;
  /** The reason why the dispute has been raised */
  reason?: Maybe<DisputeReason>;
  /** The details behind the reason */
  reasonDetails?: Maybe<Array<DisputeReasonDetail>>;
  /** The message sent from payfac explainging the reason */
  reasonMessage?: Maybe<Scalars['String']>;
  /** The final resolution from a resolved dispute, pending once a challenge has been made. won / lost will be returned from the pay fac */
  resolution?: Maybe<DisputeResolutionType>;
  /** The time of the final resolution */
  resolvedAt?: Maybe<Scalars['DateTime']>;
  /** The current status of the dispute */
  status?: Maybe<DisputeStatus>;
};

/** A list of disputes associated with the merchant. */
export type DisputeConnection = {
  __typename?: 'DisputeConnection';
  /** A list of edges. */
  edges: Array<Maybe<DisputeEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<Dispute>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** The dispute field to query by */
export enum DisputeDateRangeType {
  DisputeCreated = 'DISPUTE_CREATED'
}

/** An edge in a connection. */
export type DisputeEdge = {
  __typename?: 'DisputeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Dispute;
};

/** Ways in which lists of disputes can be ordered upon return */
export type DisputeOrder = {
  /** The direction in which to order by the specified field */
  direction: OrderDirection;
  /** The field in which to order disputes */
  field: DisputeOrderField;
};

/** The dispute field to order by */
export enum DisputeOrderField {
  Timestamp = 'TIMESTAMP'
}

/** The reason the disputes was raised */
export enum DisputeReason {
  Fraud = 'FRAUD',
  Inquiry = 'INQUIRY',
  NotAsDescribed = 'NOT_AS_DESCRIBED',
  ProcessingError = 'PROCESSING_ERROR',
  Recognition = 'RECOGNITION',
  ServicesNotProvided = 'SERVICES_NOT_PROVIDED',
  Unknown = 'UNKNOWN'
}

/** The details for the card company explaining the reason for the dispute */
export type DisputeReasonDetail = {
  __typename?: 'DisputeReasonDetail';
  /** The code originating from the finance / card company */
  code: Scalars['String'];
  /** The message related to the code */
  message: Scalars['String'];
};

/** The outcome of the dispute, won, lost or pending */
export enum DisputeResolutionType {
  Lost = 'LOST',
  Pending = 'PENDING',
  Won = 'WON'
}

/** The status of the dispute, conceded-awaiting-payfac and challenge-awating-payfac are while we are waiting for the pay fac to respond */
export enum DisputeStatus {
  AwaitingChargebackDecision = 'AWAITING_CHARGEBACK_DECISION',
  AwaitingMerchantResponse = 'AWAITING_MERCHANT_RESPONSE',
  ChallengeAwaitingPayfac = 'CHALLENGE_AWAITING_PAYFAC',
  ConcededAwaitingPayfac = 'CONCEDED_AWAITING_PAYFAC',
  PendingPayfacReview = 'PENDING_PAYFAC_REVIEW',
  Resolved = 'RESOLVED',
  Unknown = 'UNKNOWN'
}

/** The type of dispute, inquiry or chargeback */
export enum DisputeType {
  Chargeback = 'CHARGEBACK',
  Inquiry = 'INQUIRY'
}

/** The current status of the document */
export enum DocumentStatus {
  InReview = 'IN_REVIEW',
  Rejected = 'REJECTED',
  Unspecified = 'UNSPECIFIED',
  Verified = 'VERIFIED'
}

/** Input for document tokens. */
export type DocumentTokenInput = {
  /** the type of document the token relates to */
  documentType: DocumentType;
  /** the subject the documents relate to */
  subject: DocumentTokenSubject;
  /** The token returned from wepay */
  token: Scalars['String'];
};

/** Values for document token subject. */
export enum DocumentTokenSubject {
  ControllerPersonalVerification = 'CONTROLLER_PERSONAL_VERIFICATION',
  EntityVerification = 'ENTITY_VERIFICATION',
  Representative_0 = 'REPRESENTATIVE_0',
  Representative_1 = 'REPRESENTATIVE_1',
  Representative_2 = 'REPRESENTATIVE_2',
  Representative_3 = 'REPRESENTATIVE_3'
}

/** The type of document used as evidence during legal entity onboarding. */
export enum DocumentType {
  BenefitsCard = 'BENEFITS_CARD',
  BirthCertificate = 'BIRTH_CERTIFICATE',
  CertificateOfCitizenship = 'CERTIFICATE_OF_CITIZENSHIP',
  CertificateOfNaturalization = 'CERTIFICATE_OF_NATURALIZATION',
  CertifiedCopyOfCourtOrder = 'CERTIFIED_COPY_OF_COURT_ORDER',
  CurrentBankStatement = 'CURRENT_BANK_STATEMENT',
  CurrentLeaseContract = 'CURRENT_LEASE_CONTRACT',
  CurrentLocalTaxBill = 'CURRENT_LOCAL_TAX_BILL',
  CurrentUtilityBill = 'CURRENT_UTILITY_BILL',
  DriversLicense = 'DRIVERS_LICENSE',
  ElectoralRegisterEntry = 'ELECTORAL_REGISTER_ENTRY',
  EmploymentAuthorizationCard = 'EMPLOYMENT_AUTHORIZATION_CARD',
  EvidenceOfAuthority = 'EVIDENCE_OF_AUTHORITY',
  EvidenceOfCorporateRegistration = 'EVIDENCE_OF_CORPORATE_REGISTRATION',
  EvidenceOfNonProfitRegistration = 'EVIDENCE_OF_NON_PROFIT_REGISTRATION',
  MortgageStatement = 'MORTGAGE_STATEMENT',
  OtherGovernmentIssuedPhotoId = 'OTHER_GOVERNMENT_ISSUED_PHOTO_ID',
  Passport = 'PASSPORT',
  PermanentResidentCard = 'PERMANENT_RESIDENT_CARD',
  SocialInsuranceNumberCard = 'SOCIAL_INSURANCE_NUMBER_CARD',
  SocialSecurityCard = 'SOCIAL_SECURITY_CARD',
  Unspecified = 'UNSPECIFIED'
}

export type Features = {
  __typename?: 'Features';
  /** Set of credit memo feature flags enabled/disabled for the account. */
  creditMemos: CreditMemoFeatures;
  /** Set of custom email settings feature flags enabled/disabled for the account. */
  customEmailSettings: CustomEmailSettingsFeatures;
  /** Set of customer feature flags enabled/disabled for the account. */
  customers: CustomersFeatures;
  /** Set of payment methods feature flags enabled/disabled for the account. */
  paymentMethods: PaymentMethodFeatures;
  /** Set of payment requests feature flags enabled/disabled for the account. */
  paymentRequests: PaymentRequestFeatures;
  /** Set of payment feature flags enabled/disabled for the account. */
  payments: PaymentsFeatures;
  /** Set of refund feature flags enabled/disabled for the account. */
  refunds: RefundsFeatures;
};

/** The format of the file */
export enum FileFormat {
  Csv = 'CSV',
  Html = 'HTML',
  Pdf = 'PDF'
}

/** Input data for generating statement access Url */
export type FileName = {
  /** File name for statement */
  name: Scalars['String'];
};

/** Status response for mutation for uploading media. */
export type ImageStatus = {
  __typename?: 'ImageStatus';
  /** Whether the delete was successful, failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Publicly accessible image URI */
  imageUri?: Maybe<Scalars['String']>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  /** The unique id (generated file name) of the uploaded file */
  uniqueId?: Maybe<Scalars['ID']>;
};

/** Type of uploaded image */
export enum ImageType {
  Logo = 'LOGO',
  Other = 'OTHER'
}

/** The integration mode */
export enum IntegrationMode {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL'
}

/** The integration settings. */
export type IntegrationSettings = {
  __typename?: 'IntegrationSettings';
  /** The integration mode */
  mode: IntegrationMode;
  /** The settings for the preview mode */
  previewSettings: PreviewSettings;
  /** The flag indicates whether to process unsent payment request communications */
  processUnsentCommunications?: Maybe<Scalars['Boolean']>;
};


export type KeyValuePair = {
  __typename?: 'KeyValuePair';
  /** The key. */
  key: Scalars['String'];
  /** The value. */
  value?: Maybe<Scalars['String']>;
};

/** Data used for Level 2/Level 3 processing. */
export type L2L3Data = {
  __typename?: 'L2L3Data';
  /** Customer reference number. (Constraints: Maximum Length: 255) */
  customerReferenceNumber?: Maybe<Scalars['String']>;
  /** The order items. */
  lineItems?: Maybe<Array<L2L3DataLineItem>>;
  /** The order type. */
  orderType?: Maybe<L2L3OrderType>;
  /** The short description of the order. (Constraints: Maximum Length: 255) */
  shortDescription?: Maybe<Scalars['String']>;
  /** The tax amount for the order. */
  taxAmount?: Maybe<Scalars['Int']>;
};

/** Input data for Level 2/Level 3 data processing. */
export type L2L3DataInput = {
  /** Customer reference number. (Constraints: Maximum Length: 255) */
  customerReferenceNumber: Scalars['String'];
  /** The order items. */
  lineItems?: Maybe<Array<L2L3DataLineItemInput>>;
  /** The order type. */
  orderType: L2L3OrderType;
  /** The short description of the order. (Constraints: Maximum Length: 255) */
  shortDescription: Scalars['String'];
  /** The tax amount for the order. */
  taxAmount: Scalars['Int'];
};

/** Order line item for Level 2/Level 3 data processing. */
export type L2L3DataLineItem = {
  __typename?: 'L2L3DataLineItem';
  /** ISO 4217 currency code. */
  currency?: Maybe<CurrencyType>;
  /** Text description (especially for services and invoices). (Constraints: Maximum Length: 2047) */
  description?: Maybe<Scalars['String']>;
  /** The number of items ordered. */
  quantity?: Maybe<Scalars['Int']>;
  /** Tax Amount. */
  taxAmount?: Maybe<Scalars['Int']>;
  /** The tax amount for the order. */
  totalAmount?: Maybe<Scalars['Int']>;
  /** The unit of measure relative to the line item. (Constraints: Maximum Length: 255) */
  unitOfMeasure?: Maybe<Scalars['String']>;
  /** Price per unit. */
  unitPrice?: Maybe<Scalars['Int']>;
};

/** Order line item for Level 2/Level 3 data processing. */
export type L2L3DataLineItemInput = {
  /** ISO 4217 currency code. */
  currency: CurrencyType;
  /** Text description (especially for services and invoices). (Constraints: Maximum Length: 2047) */
  description: Scalars['String'];
  /** The number of items ordered. */
  quantity: Scalars['Int'];
  /** Tax Amount. */
  taxAmount?: Maybe<Scalars['Int']>;
  /** The tax amount for the order. */
  totalAmount: Scalars['Int'];
  /** The unit of measure relative to the line item. (Constraints: Maximum Length: 255) */
  unitOfMeasure: Scalars['String'];
  /** Price per unit. */
  unitPrice: Scalars['Int'];
};

/** The type of order for Level 2/Level 3 processing. */
export enum L2L3OrderType {
  Donation = 'DONATION',
  Event = 'EVENT',
  Goods = 'GOODS',
  Personal = 'PERSONAL',
  Service = 'SERVICE'
}

/** Order line item for Risk Metadata & Level 2/Level 3 data processing. */
export type LineItem = {
  __typename?: 'LineItem';
  /** ISO 4217 currency code. */
  currency: CurrencyType;
  /** Text description (especially for services and invoices). (Constraints: Maximum Length: 2047) */
  description: Scalars['String'];
  /** The number of items ordered. */
  quantity: Scalars['Int'];
  /** The tax amount for the order. */
  totalAmount: Scalars['Int'];
  /** The unit of measure relative to the line item. (Constraints: Maximum Length: 255) */
  unitOfMeasure: Scalars['String'];
  /** Price per unit. */
  unitPrice: Scalars['Int'];
};

/** Order line item. */
export type LineItemInput = {
  /** ISO 4217 currency code. */
  currency: CurrencyType;
  /** Text description (especially for services and invoices). (Constraints: Maximum Length: 2047) */
  description: Scalars['String'];
  /** The number of items ordered. */
  quantity: Scalars['Int'];
  /** The tax amount for the order. */
  totalAmount: Scalars['Int'];
  /** The unit of measure relative to the line item. (Constraints: Maximum Length: 255) */
  unitOfMeasure: Scalars['String'];
  /** Price per unit. */
  unitPrice: Scalars['Int'];
};

/** Login context. */
export type LoginContext = {
  __typename?: 'LoginContext';
  accounts: Array<TenantAccount>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

/** Status response for mutation for uploading media. */
export type MediaStatus = {
  __typename?: 'MediaStatus';
  /** Whether the delete was successful, failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  /** The unique id (generated file name) of the uploaded file */
  uniqueId?: Maybe<Scalars['ID']>;
};

/** Status response for mutation for uploading media. */
export type MediaStatus2 = {
  __typename?: 'MediaStatus2';
  /** Whether the delete was successful, failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  /** The unique id (generated file name "filename_uuid") of the uploaded file */
  uniqueId?: Maybe<Scalars['String']>;
};

export type MerchantInfo = {
  __typename?: 'MerchantInfo';
  /** Accepted payment methods for the merchant */
  acceptedPaymentMethods?: Maybe<Array<AcceptedPaymentMethod>>;
  /** The merchant’s country */
  country?: Maybe<Scalars['String']>;
  /** Three-letter ISO currency code representing the default currency for the merchant. */
  defaultCurrency?: Maybe<CurrencyType>;
  /** Set of feature flags enabled/disabled for the merchant. */
  features?: Maybe<Features>;
  /** Unique identifier for the merchant */
  id: Scalars['ID'];
  /** The url for the merchant logo */
  logoUrl?: Maybe<Scalars['String']>;
  /** Name of the company or business. */
  name?: Maybe<Scalars['String']>;
  /** Set of miscellaneous options on the account */
  options?: Maybe<AccountOptions>;
  /** The owner of the merchant account. */
  owner?: Maybe<Owner>;
  /** The refund policy */
  refundPolicy?: Maybe<Scalars['String']>;
  /** The description to appear on statements */
  statementDescription?: Maybe<Scalars['String']>;
  /** Supported payment methods enabled for the merchant. */
  supportedPaymentMethods?: Maybe<Array<PaymentMethodType>>;
};

/** Represents an amount of money with its currency type. */
export type Money = {
  __typename?: 'Money';
  /** The amount */
  amount: Scalars['Int'];
  /** The three-letter currency code defined in ISO 4217. */
  currency: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Attaches a payment method to person or tenant. */
  attachPaymentMethod: AttachPaymentMethodStatus;
  /** Cancel a pre-authorized payment. */
  cancelPayment: CancelPaymentStatus;
  /** Capture a payment. */
  capturePayment: CapturePaymentStatus;
  /** Completes a Payment Method Request */
  completePaymentMethodRequest: CompletePaymentMethodRequestStatus;
  /** [NOT ACCESSIBLE BY API] Concede the dispute, and explanation must be provided */
  concedeDispute: MutationStatusCode;
  /** Converts a payfac payment method token. Returned token has a 15 min expiration. */
  convertPayfacPaymentMethodToken: ConvertPayfacPaymentMethodTokenStatus;
  /** Converts a payment method claim to a payment method */
  convertPaymentMethodClaim: ConvertPaymentMethodClaimStatus;
  /** Create a checkout session. */
  createCheckoutSession: CreateCheckoutSessionStatus;
  /** Create a payment. */
  createPayment: CreatePaymentStatus;
  /** Creates a new payment method. */
  createPaymentMethod: CreatePaymentMethodStatus;
  /** [NOT ACCESSIBLE BY API] Creates a new payment method claim. */
  createPaymentMethodClaim: CreatePaymentMethodClaimStatus;
  /** Create a refund. The payment id, refund reason and amount refunded are all required. */
  createRefund: CreateRefundStatus;
  /** Create the report */
  createReport: CreateReportStatus;
  /** Deletes a payment method. */
  deletePaymentMethod: DeletePaymentMethodStatus;
  /** [NOT ACCESSIBLE BY API] Delete the person. Note: soft delete only */
  deletePerson: DeletePersonStatus;
  /** Detaches a payment method from person or tenant. */
  detachPaymentMethod: DetachPaymentMethodStatus;
  /** Get statement access url, use the file name when creating a statement access url request. */
  generateStatementAccessUrl: StatementAccessUrlRequestStatus;
  /** [NOT ACCESSIBLE BY API] Forwards the payment request to the mentioned email address. */
  paymentRequestForward: PaymentRequestForwardStatus;
  /**
   * Start processing the refund. The payment id, refund reason and amount refunded are all required.
   * @deprecated Deprecated in favor of the createRefund mutation
   */
  processRefund: ProcessRefundStatus;
  /** [NOT ACCESSIBLE BY API] Remove the customer user */
  removeCustomerUser: DeletePersonStatus;
  /** Resend payment receipt */
  resendPaymentReceipt: ResendPaymentReceiptStatus;
  /** Send the test email */
  sendTestEmail: SendTestEmailStatus;
  /** [NOT ACCESSIBLE BY API] Submit documentation (tokens) used to verify the merchant */
  submitVerification: SubmitVerificationStatus;
  /** Update the convenience fees for the tenant account */
  updateConvenienceFees: UpdateConvenienceFeesStatus;
  /** [NOT ACCESSIBLE BY API] Challenge the dispute, the explaination and document tokens are required */
  updateDispute: MutationStatusCode;
  /** Update the tenant account integration settings */
  updateIntegrationSettings: UpdateIntegrationSettingsStatus;
  /** Updates a payment method. */
  updatePaymentMethod: UpdatePaymentMethodStatus;
  /** Update the payment reminder settings for the tenant account */
  updatePaymentReminderSettings: UpdatePaymentReminderSettingsStatus;
  /** Update the email settings for the tenant account */
  updateTenantAccountEmailSettings: UpdateTenantAccountEmailSettingsStatus;
  /** Update the payout settings for the tenant account */
  updateTenantTransitionStatus: UpdatePayoutSettingsStatus;
  /** Uploads the PDF file required by the payment request, use the unique id returned as the invoiceRef when creating a payment request. */
  upload: MediaStatus;
  /** Uploads the PDF file required by the payment request, use the unique id returned as the invoiceRef when creating a payment request. */
  upload2: MediaStatus2;
  /** Uploads the image into publicly accessible image container on azure blob storage. */
  uploadImage: ImageStatus;
  /** Upsert a credit memo. */
  upsertCreditMemo: UpsertCreditMemoStatus;
  /** Create or update the customer */
  upsertCustomer: UpsertCustomerStatus;
  /** [NOT ACCESSIBLE BY API] Create or update the customer user */
  upsertCustomerUser: UpsertPersonStatus;
  /** [NOT ACCESSIBLE BY API] Create or Update a payer person */
  upsertPayer: UpsertPersonStatus;
  /** Create or update a Payment Method Request */
  upsertPaymentMethodRequest: UpsertPaymentMethodRequestStatus;
  /** Creates a new payment request. The reference number must be unique and the invoice ref must correspond to a pdf file that has already been uploaded. */
  upsertPaymentRequest: UpsertPaymentRequestStatus;
  /** Creates a new payment request without an invoice ref. The reference number must be unique. */
  upsertPaymentRequestNoInvoice: UpsertPaymentRequestStatus;
  /** [NOT ACCESSIBLE BY API] Create or update the payout frequency schedule. This can only be called once the tenant account has been created with wepay */
  upsertPayoutFrequency: UpsertPayoutFrequencyStatus;
  /** [NOT ACCESSIBLE BY API] Create or update the payout settings. This can only be called once the tenant account has been created with wepay */
  upsertPayoutSettings: UpsertPayoutSettingsStatus;
  /** [NOT ACCESSIBLE BY API] Create or update the person / user */
  upsertPerson: UpsertPersonStatus;
  /** [NOT ACCESSIBLE BY API] Update the tenant account properties */
  upsertTenantAccount: UpsertTenantAccountStatus;
  /** [NOT ACCESSIBLE BY API] Verify bank payment method with microdeposits. */
  verifyPaymentMethod: VerifyPaymentMethodStatus;
};


export type MutationAttachPaymentMethodArgs = {
  input: AttachPaymentMethodInput;
};


export type MutationCancelPaymentArgs = {
  input: CancelPaymentInput;
};


export type MutationCapturePaymentArgs = {
  input: CapturePaymentInput;
};


export type MutationCompletePaymentMethodRequestArgs = {
  input: CompletePaymentMethodRequestInput;
};


export type MutationConcedeDisputeArgs = {
  input: ConcedeDisputeInput;
};


export type MutationConvertPayfacPaymentMethodTokenArgs = {
  input: ConvertPayfacPaymentMethodTokenInput;
};


export type MutationConvertPaymentMethodClaimArgs = {
  input: ConvertPaymentMethodClaimInput;
};


export type MutationCreateCheckoutSessionArgs = {
  input: CreateCheckoutSessionInput;
};


export type MutationCreatePaymentArgs = {
  input: CreatePaymentInput;
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};


export type MutationCreatePaymentMethodClaimArgs = {
  input: CreatePaymentMethodClaimInput;
};


export type MutationCreateRefundArgs = {
  input: CreateRefundInput;
};


export type MutationCreateReportArgs = {
  input: CreateReportInput;
};


export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};


export type MutationDeletePersonArgs = {
  input: DeletePersonInput;
};


export type MutationDetachPaymentMethodArgs = {
  input: DetachPaymentMethodInput;
};


export type MutationGenerateStatementAccessUrlArgs = {
  input: FileName;
};


export type MutationPaymentRequestForwardArgs = {
  input: PaymentRequestForwardInput;
};


export type MutationProcessRefundArgs = {
  input: ProcessRefundInput;
};


export type MutationRemoveCustomerUserArgs = {
  input: RemoveCustomerUserInput;
};


export type MutationResendPaymentReceiptArgs = {
  input: ResendPaymentReceiptInput;
};


export type MutationSendTestEmailArgs = {
  input: SendTestEmailInput;
};


export type MutationSubmitVerificationArgs = {
  input: SubmitVerificationInput;
};


export type MutationUpdateConvenienceFeesArgs = {
  input: UpdateConvenienceFeesInput;
};


export type MutationUpdateDisputeArgs = {
  input: UpdateDisputeInput;
};


export type MutationUpdateIntegrationSettingsArgs = {
  input: UpdateIntegrationSettingsInput;
};


export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};


export type MutationUpdatePaymentReminderSettingsArgs = {
  input: UpdatePaymentReminderSettingsInput;
};


export type MutationUpdateTenantAccountEmailSettingsArgs = {
  input: UpdateTenantAccountEmailSettingsInput;
};


export type MutationUpdateTenantTransitionStatusArgs = {
  input: UpdateTenantAccountTransitionStatusInput;
};


export type MutationUploadArgs = {
  input: UploadInput;
};


export type MutationUpload2Args = {
  input: UploadInput;
};


export type MutationUploadImageArgs = {
  input: UploadImageInput;
};


export type MutationUpsertCreditMemoArgs = {
  input: UpsertCreditMemoInput;
};


export type MutationUpsertCustomerArgs = {
  input: UpsertCustomerInput;
};


export type MutationUpsertCustomerUserArgs = {
  input: UpsertCustomerUserInput;
};


export type MutationUpsertPayerArgs = {
  input: UpsertPayerInput;
};


export type MutationUpsertPaymentMethodRequestArgs = {
  input: UpsertPaymentMethodRequestInput;
};


export type MutationUpsertPaymentRequestArgs = {
  input: UpsertPaymentRequestInput;
};


export type MutationUpsertPaymentRequestNoInvoiceArgs = {
  input: UpsertPaymentRequestNoInvoiceInput;
};


export type MutationUpsertPayoutFrequencyArgs = {
  input: UpsertPayoutFrequencyInput;
};


export type MutationUpsertPayoutSettingsArgs = {
  input: UpsertPayoutSettingsInput;
};


export type MutationUpsertPersonArgs = {
  input: UpsertPersonInput;
};


export type MutationUpsertTenantAccountArgs = {
  input: UpsertTenantAccountInput;
};


export type MutationVerifyPaymentMethodArgs = {
  input: VerifyPaymentMethodInput;
};

/** All possible statuses for mutations */
export enum MutationStatusCode {
  Error = 'ERROR',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

/** Data used for Level 2/Level 3 processing & rbits. */
export type OrderDetails = {
  __typename?: 'OrderDetails';
  /** Customer reference number. (Constraints: Maximum Length: 255) */
  customerReferenceNumber: Scalars['String'];
  /** The order items. */
  lineItems: Array<LineItem>;
  /** The order type. */
  orderType: OrderType;
  /** The short description of the order. (Constraints: Maximum Length: 255) */
  shortDescription: Scalars['String'];
  /** The tax amount for the order. */
  taxAmount: Scalars['Int'];
};

/** Input data for Risk Metadata & Level 2/Level 3 data processing. */
export type OrderDetailsInput = {
  /** Customer reference number. (Constraints: Maximum Length: 255) */
  customerReferenceNumber: Scalars['String'];
  /** The order items. */
  lineItems: Array<LineItemInput>;
  /** The order type. */
  orderType: OrderType;
  /** The short description of the order. (Constraints: Maximum Length: 255) */
  shortDescription: Scalars['String'];
  /** The tax amount for the order. */
  taxAmount: Scalars['Int'];
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Input data for a sales order line item */
export type OrderLineItemInput = {
  /** Currency of the associated price. */
  currency: CurrencyType;
  /** Description of the line item. (Constraints: Maximum Length: 2047) */
  description: Scalars['String'];
  /** Price of the line item */
  price: Scalars['Int'];
  /** Quantity of the line item. */
  quantity: Scalars['Int'];
};

/** The type of order. */
export enum OrderType {
  Donation = 'DONATION',
  Event = 'EVENT',
  Goods = 'GOODS',
  Personal = 'PERSONAL',
  Service = 'SERVICE',
  Unknown = 'UNKNOWN'
}

/** The owner data attached to most of the data objects, only the relevant properties will be populated */
export type Owner = {
  __typename?: 'Owner';
  /** The Id of the adjustment */
  adjustmentId?: Maybe<Scalars['ID']>;
  /** The Id of the customer */
  customerId?: Maybe<Scalars['ID']>;
  /** The Id of the dispute */
  disputeId?: Maybe<Scalars['ID']>;
  /** The Id of the payment */
  paymentId?: Maybe<Scalars['ID']>;
  /** The Id of the payment request */
  paymentRequestId?: Maybe<Scalars['ID']>;
  /** The Id of the payout */
  payoutId?: Maybe<Scalars['ID']>;
  /** The Id of the refund */
  personId?: Maybe<Scalars['ID']>;
  /** The Id of the recovery */
  recoveryId?: Maybe<Scalars['ID']>;
  /** The Id of the refund */
  refundId?: Maybe<Scalars['ID']>;
  /** The Id of the tenant */
  tenantId: Scalars['ID'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor for the last items in the connection, use this as the start when you want to get the next page */
  endCursor?: Maybe<Scalars['String']>;
  /** Whether the connection can return any more results */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** whether the connetion can return any previous pages */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** The cursor for the first item in the connection */
  startCursor?: Maybe<Scalars['String']>;
};

/** The Payment Facilitator. */
export enum PayFac {
  Ptt = 'PTT',
  Unknown = 'UNKNOWN',
  Wepay = 'WEPAY'
}

export type PayFacId = {
  __typename?: 'PayFacId';
  /** Id of the resource. */
  resourceId?: Maybe<Scalars['String']>;
  /** Type of resource. */
  resourceType?: Maybe<Scalars['String']>;
};

export type PayFacIds = {
  __typename?: 'PayFacIds';
  /** List of resource ids and types. */
  ids?: Maybe<Array<PayFacId>>;
};

/** A list of credit memos associated with the payer. */
export type PayerCreditMemoConnection = {
  __typename?: 'PayerCreditMemoConnection';
  /** A list of edges. */
  edges: Array<Maybe<CreditMemoEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<CreditMemo>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total remaining balance. */
  totalBalance: Scalars['Int'];
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

export type PayerDetails = {
  __typename?: 'PayerDetails';
  /** The address */
  address?: Maybe<Address>;
  /** The email address */
  email: Scalars['String'];
  /** The card/account holder name */
  name: Scalars['String'];
  /** The phone muber */
  phone?: Maybe<Phone>;
};

/** Input data for the payer */
export type PayerDetailsInput = {
  /** The address of the payment method card/account holder */
  address?: Maybe<AddressInputType>;
  /** The email address of the payment method card/account holder. (Constraints: Maximum Length: 254) */
  email?: Maybe<Scalars['String']>;
  /** The name of the payment method card/account holder. (Constraints: Minimum Length: 3, Maximum Length: 26, Pattern: ^[^\x00-\x1f]+$) */
  name?: Maybe<Scalars['String']>;
  /** The phone number of the payment method card/account holder */
  phone?: Maybe<PhoneInputType>;
};

/** A payer merchant payments due summary */
export type PayerMerchantSummary = {
  __typename?: 'PayerMerchantSummary';
  /** The earliest/minimum created date from the all payment requests */
  earliestCreatedDate?: Maybe<Scalars['DateTime']>;
  /** The earliest/minimum due date from the all payment requests */
  earliestDueDate?: Maybe<Scalars['DateTime']>;
  /** The details of the merchant */
  merchantInfo?: Maybe<MerchantInfo>;
  /** The number of unpaid payment requests */
  numberOfUnpaidRequests: Scalars['Int'];
  /** Pending payment method request */
  pendingPaymentMethodRequest?: Maybe<PaymentMethodRequest>;
  /** The total amount due */
  totalAmount: Scalars['Int'];
  /** The remaining amount on outstanding payment requests */
  totalDue: Scalars['Int'];
  /** The total amount paid */
  totalPaid: Scalars['Int'];
  /** The total amount refunded */
  totalRefunded: Scalars['Int'];
};

export type PayerPaymentMethod = {
  __typename?: 'PayerPaymentMethod';
  /** Indicates if this payment method is the default for the payer. */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** A payment method */
  paymentMethod?: Maybe<PaymentMethod>;
};

export type PayerProfile = {
  __typename?: 'PayerProfile';
  /** List of payment methods. */
  paymentMethods?: Maybe<Array<PayerPaymentMethod>>;
};

/** A payer transaction */
export type PayerTransaction = {
  __typename?: 'PayerTransaction';
  /**
   * The payment amount
   * @deprecated Please use Payment field
   */
  amount?: Maybe<Scalars['Int']>;
  /**
   * The currency of the payment
   * @deprecated Please use Payment field
   */
  currency?: Maybe<CurrencyType>;
  /**
   * The reference number from the payment request
   * @deprecated Please use PaymentRequest field
   */
  invoiceReferenceNumber: Scalars['String'];
  /** The country of the merchant */
  merchantCountry: Scalars['String'];
  /** The name of the merchant payee */
  merchantName: Scalars['String'];
  /** The payer. */
  payer?: Maybe<Person>;
  /** The payment. */
  payment?: Maybe<Payment>;
  /**
   * The date for the payment
   * @deprecated Please use Payment field
   */
  paymentDate?: Maybe<Scalars['DateTime']>;
  /**
   * The id for the payment
   * @deprecated Please use Payment field
   */
  paymentId?: Maybe<Scalars['String']>;
  /** The payment method used for payment. */
  paymentMethod?: Maybe<PaymentMethod>;
  /** The payment request. */
  paymentRequest?: Maybe<PaymentRequest>;
  /**
   * The create timestamp for the payment request
   * @deprecated Please use Payment field
   */
  paymentRequestCreatedTimestamp: Scalars['DateTime'];
  /**
   * The id for the payment request
   * @deprecated Please use Payment field
   */
  paymentRequestId: Scalars['String'];
  /** The payment url token */
  paymentUrlToken?: Maybe<Scalars['String']>;
  /**
   * The id for the payer
   * @deprecated Please use Payment field
   */
  personId: Scalars['String'];
  /**
   * The status of the payment request
   * @deprecated Please use PaymentRequest field
   */
  status: PaymentRequestStatus;
  /** The id of the merchant payee */
  tenantId: Scalars['String'];
};

/** A list of transactions associated with the payer. */
export type PayerTransactionConnection = {
  __typename?: 'PayerTransactionConnection';
  /** A list of edges. */
  edges: Array<Maybe<PayerTransactionEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<PayerTransaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PayerTransactionEdge = {
  __typename?: 'PayerTransactionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PayerTransaction;
};

/** Ways in which lists of payer transactions can be ordered upon return */
export type PayerTransactionOrder = {
  /** The direction in which to order payer transactions by the specified field */
  direction: OrderDirection;
  /** The field in which to order payer transactions */
  field: PayerTransactionOrderField;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum PayerTransactionOrderField {
  AttemptTimestamp = 'ATTEMPT_TIMESTAMP',
  Timestamp = 'TIMESTAMP'
}

/** A payer transaction summary by merchant */
export type PayerTransactionSummary = {
  __typename?: 'PayerTransactionSummary';
  /** The merchant payments due summary */
  merchantSummary: Array<PayerMerchantSummary>;
  /** The payer. */
  payer?: Maybe<Person>;
};

/** A payment record */
export type Payment = {
  __typename?: 'Payment';
  /** The amount of the payment, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** The payment amount excluding convenience fees */
  amountBeforeFees?: Maybe<Scalars['Int']>;
  /** The amount being disputed against this payment */
  amountDisputed?: Maybe<Scalars['Int']>;
  /** The amount refunded against the payment */
  amountRefunded?: Maybe<Scalars['Int']>;
  /** The time the payment was attempted */
  attemptTimestamp?: Maybe<Scalars['DateTime']>;
  /** The authorization code for the payment */
  authorizationCode?: Maybe<Scalars['String']>;
  /** The reason why the payment was cancelled */
  cancelReason?: Maybe<Scalars['String']>;
  /** The time the payment was captured */
  capturedAt?: Maybe<Scalars['DateTime']>;
  /** The time the payment was completed */
  completedTimestamp?: Maybe<Scalars['DateTime']>;
  /** The convenience fees amount */
  convenienceFee?: Maybe<Scalars['Int']>;
  /** The time the payment was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The amount used from the credit memo, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  creditAmount?: Maybe<Scalars['Int']>;
  /** The list of associated credit memos */
  creditMemoAllocation?: Maybe<Array<CreditMemoAllocation>>;
  /** The currency used for this payment */
  currency?: Maybe<CurrencyType>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The customer associated to payment. */
  customer?: Maybe<Customer>;
  /** Customer PO number */
  customerPONumber?: Maybe<Scalars['String']>;
  /** The description of the payment */
  description?: Maybe<Scalars['String']>;
  /** Details of what failed */
  failureDetail?: Maybe<ReasonInfo>;
  /** The reason why the payment failed */
  failureReason?: Maybe<Scalars['String']>;
  /** The fee for this payment (lowest denomination). (Constraints: >= 0) */
  feeAmount?: Maybe<Scalars['Int']>;
  /** The Id for the payment */
  id: Scalars['String'];
  /** Indicates whether to immediately capture payment amount */
  immediateCapture?: Maybe<Scalars['Boolean']>;
  /** The user that initiated the payment */
  initiatedBy?: Maybe<Scalars['String']>;
  /** Invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Level 2/Level 3 processing data */
  l2l3Data?: Maybe<L2L3Data>;
  /** Order number */
  orderNumber?: Maybe<Scalars['String']>;
  /** The owner of the payment. */
  owner: Owner;
  /** The used payment method */
  paymentMethod?: Maybe<PaymentMethod>;
  /**
   * The parent payment request
   * @deprecated Use paymentRequestAllocation instead
   */
  paymentRequest?: Maybe<PaymentRequest>;
  /** The list of associated payment requests */
  paymentRequestAllocation?: Maybe<Array<PaymentRequestAllocation>>;
  /** The reason why the payment is pending */
  pendingReason?: Maybe<Scalars['String']>;
  /** The reason code for the pending payment */
  pendingReasonCode?: Maybe<PendingReasonCode>;
  /** A list of the refunds made against this payment */
  refunds: Array<Maybe<Refund>>;
  /** The current status of the payment */
  status: PaymentStatus;
  /** Total payment amount inclusive of convenience fee and credit amount */
  totalAmount?: Maybe<Scalars['Int']>;
};

/** The Payment Attempt */
export type PaymentAttempt = {
  __typename?: 'PaymentAttempt';
  amount: Scalars['Int'];
  customerPONumber?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentRequest?: Maybe<PaymentRequest>;
  timestamp: Scalars['DateTime'];
};

/** Input for the Payment Attempt */
export type PaymentAttemptInput = {
  amount: Scalars['Int'];
  customerPONumber?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['ID']>;
  paymentRequestId?: Maybe<Scalars['ID']>;
  timestamp: Scalars['DateTime'];
};

export type PaymentBank = {
  __typename?: 'PaymentBank';
  accountHolder?: Maybe<PaymentMethodHolder>;
  accountType?: Maybe<BankAccountType>;
  lastFour?: Maybe<Scalars['String']>;
};

export type PaymentBankInfo = {
  __typename?: 'PaymentBankInfo';
  /** The bank account type */
  accountType?: Maybe<BankAccountType>;
  /** The last four digits of the bank account */
  lastFour?: Maybe<Scalars['String']>;
};

/** A list of payments associated with the merchant. */
export type PaymentConnection = {
  __typename?: 'PaymentConnection';
  /** A list of edges. */
  edges: Array<Maybe<PaymentEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<Payment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

export enum PaymentDateRangeType {
  PaymentCompleted = 'PAYMENT_COMPLETED',
  PaymentRequestCreated = 'PAYMENT_REQUEST_CREATED',
  PaymentRequestDueDate = 'PAYMENT_REQUEST_DUE_DATE',
  PaymentRequestUpdated = 'PAYMENT_REQUEST_UPDATED'
}

/**
 * An edge in a connection. Think of the edge as the connection between two nodes.
 *   Its tightly coupled to the filter and order by applied to the connection, any changes to the filter or order by will invalidate the edge
 */
export type PaymentEdge = {
  __typename?: 'PaymentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Payment;
};

/** The payment information */
export type PaymentInfo = {
  __typename?: 'PaymentInfo';
  /** The amount of the payment, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** The authorization code for the payment */
  authorizationCode?: Maybe<Scalars['String']>;
  /** The time the payment was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The currency used for this payment */
  currency?: Maybe<CurrencyType>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Customer PO number */
  customerPONumber?: Maybe<Scalars['String']>;
  /** The reason why the payment failed */
  failureReason?: Maybe<Scalars['String']>;
  /** The Id for the payment */
  id: Scalars['String'];
  /** Indicates whether to immediately capture payment amount */
  immediateCapture?: Maybe<Scalars['Boolean']>;
  /** Invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Order number */
  orderNumber?: Maybe<Scalars['String']>;
  /** The owner of the payment. */
  owner: Owner;
  /** The used payment method information */
  paymentMethodInfo?: Maybe<PaymentMethodInfo>;
  /** The reason why the payment is pending */
  pendingReason?: Maybe<Scalars['String']>;
  /** The current status of the payment */
  status: PaymentStatus;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  creditCard?: Maybe<CreditCard>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Details of what failed */
  errorReason?: Maybe<ReasonInfo>;
  /** Unique identifier for the object */
  id: Scalars['ID'];
  /** Whether the payment method is long lived or not */
  isLongLived?: Maybe<Scalars['Boolean']>;
  /** The owner of the payment method. */
  owner: Owner;
  paymentBank?: Maybe<PaymentBank>;
  /** The status of payment method. */
  status?: Maybe<PaymentMethodStatus>;
  /** The type of payment method. */
  type?: Maybe<PaymentMethodType>;
  /** The timestamp when the payment method was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The user identifier who last updated the payment method */
  updatedBy?: Maybe<Scalars['String']>;
};

export type PaymentMethodClaim = {
  __typename?: 'PaymentMethodClaim';
  /** The card information. */
  cardInfo?: Maybe<CardInfo>;
  /** The timestamp when the payment method claim has/will expire(d) */
  expirationDate?: Maybe<Scalars['String']>;
  /** Unique identifier for the object */
  id: Scalars['ID'];
  /** The owner of the payment method claim. */
  owner: Owner;
  /** Whether the payment method claim is single use or not */
  singleUse?: Maybe<Scalars['Boolean']>;
};

/** A list of payment methods associated with the merchant. */
export type PaymentMethodConnection = {
  __typename?: 'PaymentMethodConnection';
  /** A list of edges. */
  edges: Array<Maybe<PaymentMethodEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<PaymentMethod>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/**
 * An edge in a connection. Think of the edge as the connection between two nodes.
 *   Its tightly coupled to the filter and order by applied to the connection, any changes to the filter or order by will invalidate the edge
 */
export type PaymentMethodEdge = {
  __typename?: 'PaymentMethodEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PaymentMethod;
};

export type PaymentMethodFeatures = {
  __typename?: 'PaymentMethodFeatures';
  /** Automatically update credit cards that have been stored with ApteanPay. If a card is expired, or has been replaced (e.g., due to theft), it will be automatically updated based in information provided by card networks. */
  autoUpdate: Scalars['Boolean'];
  /** Allow Merchant to request new Shared Payment Method from customer. */
  paymentMethodRequests: Scalars['Boolean'];
};

export type PaymentMethodHolder = {
  __typename?: 'PaymentMethodHolder';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  holderName?: Maybe<Scalars['String']>;
  phone?: Maybe<Phone>;
};

/** Input data for the payment method holder */
export type PaymentMethodHolderInput = {
  /** The address of the payment method card/account holder */
  address: AddressInputType;
  /** The email address of the payment method card/account holder. (Constraints: Maximum Length: 254) */
  email?: Maybe<Scalars['String']>;
  /** The name of the payment method card/account holder. (Constraints: Minimum Length: 3, Maximum Length: 26, Pattern: ^[^\x00-\x1f]+$) */
  name?: Maybe<Scalars['String']>;
  /** The phone number of the payment method card/account holder */
  phone?: Maybe<PhoneInputType>;
};

export type PaymentMethodInfo = {
  __typename?: 'PaymentMethodInfo';
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The card information */
  creditCard?: Maybe<CardInfo>;
  /** The payment bank information */
  paymentBank?: Maybe<PaymentBankInfo>;
  /** The type of payment method */
  type?: Maybe<PaymentMethodType>;
};

/** Ways in which lists of payment methods can be ordered upon return */
export type PaymentMethodOrder = {
  /** The direction in which to order payment methods by the specified field */
  direction: OrderDirection;
  /** The field in which to order payment methods */
  field: PaymentMethodOrderField;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum PaymentMethodOrderField {
  Timestamp = 'TIMESTAMP'
}

export type PaymentMethodOwnerResourceUnion = Customer | Person | TenantAccount;

/** The PaymentMethodRequest defines an instance of a payment method requested by the merchant / legal entity for customer. */
export type PaymentMethodRequest = {
  __typename?: 'PaymentMethodRequest';
  /** The timestamp when Payment Method Request was completed. */
  completedTimestamp?: Maybe<Scalars['DateTime']>;
  /** The timestamp when Payment Method Request was issued. */
  createdTimestamp?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  owner?: Maybe<Owner>;
  paymentAttempts: Array<PaymentAttempt>;
  paymentMethod?: Maybe<PaymentMethod>;
  /** Textual reason the merchant is requesting new card. */
  reason: Scalars['String'];
  restriction: PaymentMethodRequestRestriction;
  sendMerchantEmailOptions?: Maybe<SendMerchantEmailOptions>;
  /** The current status of the payment method request */
  status?: Maybe<PaymentMethodRequestStatus>;
  /** The status reason. */
  statusReason?: Maybe<Scalars['String']>;
  supportedPaymentMethods: Array<PaymentMethodType>;
};

/** A list of payments associated with the merchant. */
export type PaymentMethodRequestConnection = {
  __typename?: 'PaymentMethodRequestConnection';
  /** A list of edges. */
  edges: Array<Maybe<PaymentMethodRequestEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<PaymentMethodRequest>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/**
 * An edge in a connection. Think of the edge as the connection between two nodes.
 *   Its tightly coupled to the filter and order by applied to the connection, any changes to the filter or order by will invalidate the edge
 */
export type PaymentMethodRequestEdge = {
  __typename?: 'PaymentMethodRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PaymentMethodRequest;
};

/** Ways in which lists of payment method requests can be ordered upon return */
export type PaymentMethodRequestOrder = {
  /** The direction in which to order payment method requests by the specified field */
  direction: OrderDirection;
  /** The field in which to order payment method requests */
  field: PaymentMethodRequestOrderField;
};

/** Possible fields in which to order a list of items when provided an `orderBy` argument. */
export enum PaymentMethodRequestOrderField {
  Timestamp = 'TIMESTAMP'
}

/** Restriction on payment method request while completing the request. */
export enum PaymentMethodRequestRestriction {
  AddOnly = 'ADD_ONLY',
  AddOrSelectExisting = 'ADD_OR_SELECT_EXISTING',
  AddOrSelectExistingExcludingAttempted = 'ADD_OR_SELECT_EXISTING_EXCLUDING_ATTEMPTED'
}

/** Possible statuses for the payment method request */
export enum PaymentMethodRequestStatus {
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN'
}

/** Possible values representing the payment method status. */
export enum PaymentMethodStatus {
  Deleted = 'DELETED',
  Processing = 'PROCESSING',
  ProcessingComplete = 'PROCESSING_COMPLETE',
  ProcessingError = 'PROCESSING_ERROR',
  Unknown = 'UNKNOWN',
  Unverified = 'UNVERIFIED',
  VerificationFailed = 'VERIFICATION_FAILED',
  VerificationPending = 'VERIFICATION_PENDING',
  Verified = 'VERIFIED'
}

/** Possible values representing the payment method type. */
export enum PaymentMethodType {
  CreditCard = 'CREDIT_CARD',
  PaymentBankUs = 'PAYMENT_BANK_US'
}

/** Ways in which lists of payments can be ordered upon return */
export type PaymentOrder = {
  /** The direction in which to order payments by the specified field */
  direction: OrderDirection;
  /** The field in which to order payments */
  field: PaymentOrderField;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum PaymentOrderField {
  Timestamp = 'TIMESTAMP'
}

/** The payment reminders settings. */
export type PaymentReminderSettings = {
  __typename?: 'PaymentReminderSettings';
  /** Number of days before due date for which reminder would be sent. */
  dueDateReminderDays?: Maybe<Scalars['Int']>;
  /** Number of days after due date for which reminder would be sent. */
  overdueReminderDays?: Maybe<Scalars['Int']>;
  /** Message for reminders after due date. */
  overdueReminderMessage?: Maybe<Scalars['String']>;
  /** Message for reminders before due date. */
  reminderMessage?: Maybe<Scalars['String']>;
  sendDueDateReminder: Scalars['Boolean'];
  sendOverdueReminder: Scalars['Boolean'];
};

/** The PaymentRequest defines an instance of a payment requested by the merchant / legal entity. The payment request can have many communications, payments and refunds. */
export type PaymentRequest = {
  __typename?: 'PaymentRequest';
  /** Additional information for the payment request. */
  additionalInfo?: Maybe<Scalars['String']>;
  /** The amount requested */
  amount?: Maybe<Scalars['Int']>;
  /** A list of the communications sent to the payee against this request */
  communications: Array<Maybe<PaymentRequestCommunication>>;
  /** The time the payment request was created */
  createdAt: Scalars['DateTime'];
  /** The creator of the payment requests */
  createdBy?: Maybe<Scalars['String']>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The customer associated to payment request. */
  customer?: Maybe<Customer>;
  /** Customer PO number */
  customerPONumber?: Maybe<Scalars['String']>;
  /** The amount of money to deduct from the total amount due, if paid on or before the discount end date. */
  discountAmount?: Maybe<Scalars['Int']>;
  /** The date till the discount is applicable. */
  discountEndDate?: Maybe<Scalars['DateTime']>;
  /** The payment request due date. */
  dueDate?: Maybe<Scalars['DateTime']>;
  /** The Id for the payment request */
  id: Scalars['String'];
  /** The Id for the invoice */
  invoiceId?: Maybe<Scalars['String']>;
  /** The list of Ids for the invoices */
  invoiceIds?: Maybe<Array<Scalars['String']>>;
  /** The link to the invoice pdf file. */
  invoiceLink?: Maybe<Scalars['String']>;
  /** The list of links to the invoices pdf files. */
  invoiceLinks?: Maybe<Array<Scalars['String']>>;
  /** Invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Level 2/Level 3 processing data */
  l2l3Data?: Maybe<L2L3Data>;
  /** The time the payment request was last modified */
  modifiedTimestamp: Scalars['DateTime'];
  /** Order number */
  orderNumber?: Maybe<Scalars['String']>;
  /** The flag to check whether the overdue reminder is sent. */
  overdueReminderSent?: Maybe<Scalars['Boolean']>;
  /** The owner of the payment request. */
  owner: Owner;
  /** The link to the payment request. */
  paymentUrl?: Maybe<Scalars['String']>;
  /** The list of payments made against this request */
  payments: Array<Maybe<Payment>>;
  /** The reference number of the payment request, must be unique for the tenant */
  referenceNumber?: Maybe<Scalars['String']>;
  /** The flag to check whether the reminder is sent. */
  reminderSent?: Maybe<Scalars['Boolean']>;
  /** The current status of the payment request. Pending will be set while the payment is being processed */
  status?: Maybe<PaymentRequestStatus>;
  /** The current reason for the status */
  statusReason?: Maybe<Scalars['String']>;
  /** The remaining amount. */
  totalDue: Scalars['Int'];
  /** The total amount paid. */
  totalPaid: Scalars['Int'];
  /** The total amount refunded. */
  totalRefunded: Scalars['Int'];
};

/** A payment request allocation */
export type PaymentRequestAllocation = {
  __typename?: 'PaymentRequestAllocation';
  /** The amount of the payment, the value should be lowest denomination i.e. cents */
  amount: Scalars['Int'];
  /** The reason for partial payment */
  partialPaymentReason?: Maybe<Scalars['String']>;
  /** The associated payment request */
  paymentRequest?: Maybe<PaymentRequest>;
};

/** Input data for payment request amounts. */
export type PaymentRequestAllocationInput = {
  /** The amount of the payment, the value should be lowest denomination i.e. cents */
  amount: Scalars['Int'];
  /** The reason for partial payment. Maximum 200 characters */
  partialPaymentReason?: Maybe<Scalars['String']>;
  /** The payment request id */
  paymentRequestId: Scalars['ID'];
};

/** The Payment Request Communication represents a single attempt to ask for a payment */
export type PaymentRequestCommunication = {
  __typename?: 'PaymentRequestCommunication';
  communicationType: CommunicationType;
  /** The email address to send the communication */
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /**
   * Indicates whether it is the forwarded communication
   * @deprecated Please use subType
   */
  isForward?: Maybe<Scalars['Boolean']>;
  /**
   * Indicates whether it is a preview communication
   * @deprecated Please use subType
   */
  isPreview: Scalars['Boolean'];
  /** The owner of the payment. */
  owner: Owner;
  /** The phone number to send the communication */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The time the communication request was made */
  requestTimestamp: Scalars['DateTime'];
  /** The time the communication request was sent */
  sentTimestamp?: Maybe<Scalars['DateTime']>;
  /** The current status of the payment request communication. Pending will be set while sending the communication in the automatic mode. */
  status?: Maybe<PaymentRequestCommunicationStatus>;
  /** Subtype of the payment request communication. */
  subType?: Maybe<SubType>;
};

/** Possible statuses for the payment request communication. */
export enum PaymentRequestCommunicationStatus {
  Pending = 'PENDING',
  Sent = 'SENT',
  Unsent = 'UNSENT'
}

/** A list of payment requests associated with the merchant. */
export type PaymentRequestConnection = {
  __typename?: 'PaymentRequestConnection';
  /** A list of edges. */
  edges: Array<Maybe<PaymentRequestEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<PaymentRequest>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** A payment request credit allocation */
export type PaymentRequestCreditAllocation = {
  __typename?: 'PaymentRequestCreditAllocation';
  /** The amount of the credit memo, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** The Id for the payment request */
  paymentRequestId?: Maybe<Scalars['String']>;
  /** The reference number of the payment request */
  paymentRequestReferenceNumber?: Maybe<Scalars['String']>;
};

/**
 * An edge in a connection. Think of the edge as the connection between two nodes.
 *   Its tightly coupled to the filter and order by applied to the connection, any changes to the filter or order by will invalidate the edge
 */
export type PaymentRequestEdge = {
  __typename?: 'PaymentRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PaymentRequest;
};

export type PaymentRequestFeatures = {
  __typename?: 'PaymentRequestFeatures';
  /** Allow payer to pay consolidated payment against multiple payment requests. */
  consolidatedPayment: Scalars['Boolean'];
  /** Allow payer to pay multiple/partial payment against a single payment request. */
  partialPayment: Scalars['Boolean'];
  /** Allow payer to receive payment reminder emails against a single payment request. */
  paymentReminderEmails: Scalars['Boolean'];
};

/** Input data for forwarding a payment request */
export type PaymentRequestForwardInput = {
  /** The secondary email address of the payer, destination for email forwarding. */
  email: Scalars['String'];
  /** The payment request id) */
  paymentRequestId: Scalars['String'];
};

/** Status response for mutation forwarding a payment request. */
export type PaymentRequestForwardStatus = {
  __typename?: 'PaymentRequestForwardStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** The Payment Request Info is a condensed form of the Payment Request. */
export type PaymentRequestInfo = {
  __typename?: 'PaymentRequestInfo';
  /** Accepted payment methods. */
  acceptedPaymentMethods?: Maybe<Array<AcceptedPaymentMethod>>;
  /** Additonal Information for the payment request */
  additionalInfo?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated, the more specific supportedPaymentMethods should be used. */
  allowedPaymentTypes?: Maybe<Array<PaymentType>>;
  amount: Scalars['Int'];
  currency: CurrencyType;
  /** Owner Customer ID */
  customerId?: Maybe<Scalars['ID']>;
  /** Customer PO number */
  customerPONumber?: Maybe<Scalars['String']>;
  /** The amount of money to deduct from the total amount due, if paid on or before the discount end date. */
  discountAmount?: Maybe<Scalars['Int']>;
  /** The date till the discount is applicable. */
  discountEndDate?: Maybe<Scalars['DateTime']>;
  /** The payment request due date. */
  dueDate?: Maybe<Scalars['DateTime']>;
  /** Set of feature flags enabled/disabled for the tenant. */
  features?: Maybe<Features>;
  invoiceLink?: Maybe<Scalars['String']>;
  /** The list of links to the invoices pdf files. */
  invoiceLinks?: Maybe<Array<Scalars['String']>>;
  /** Invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  merchantName: Scalars['String'];
  merchantStatementDescription: Scalars['String'];
  /** Order number */
  orderNumber?: Maybe<Scalars['String']>;
  paymentRequestId: Scalars['String'];
  /** The list of payments made against this request */
  payments: Array<Maybe<Payment>>;
  referenceNumber: Scalars['String'];
  refundPolicy: Scalars['String'];
  status: PaymentRequestStatus;
  statusReason?: Maybe<Scalars['String']>;
  /** Supported payment methods enabled for the tenant. */
  supportedPaymentMethods?: Maybe<Array<PaymentMethodType>>;
  tenantId: Scalars['ID'];
  /** The remaining amount. */
  totalDue: Scalars['Int'];
  /** The total amount paid. */
  totalPaid: Scalars['Int'];
  /** The total amount refunded. */
  totalRefunded: Scalars['Int'];
};

/** Ways in which lists of payment requests can be ordered upon return */
export type PaymentRequestOrder = {
  /** The direction in which to order payment requests by the specified field */
  direction: OrderDirection;
  /** The field in which to order payment requests */
  field: PaymentRequestOrderField;
};

/** Properties by which payment requests connections can be ordered. */
export enum PaymentRequestOrderField {
  Timestamp = 'TIMESTAMP'
}

/** Possible statuses for the payment request. */
export enum PaymentRequestStatus {
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  FullyRefunded = 'FULLY_REFUNDED',
  PartiallyPaid = 'PARTIALLY_PAID',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Pending = 'PENDING',
  PendingAuthorization = 'PENDING_AUTHORIZATION',
  RefundFailed = 'REFUND_FAILED',
  RefundPending = 'REFUND_PENDING',
  Unknown = 'UNKNOWN',
  Unpaid = 'UNPAID'
}

/** Details on when funds from charges are available, and when they are paid out to an external account. */
export type PaymentSchedule = {
  __typename?: 'PaymentSchedule';
  interval?: Maybe<PayoutInterval>;
};

export type PaymentSettings = {
  __typename?: 'PaymentSettings';
  /** The refund policy */
  refundPolicy?: Maybe<Scalars['String']>;
  /** The description to appear on statements */
  statementDescription?: Maybe<Scalars['String']>;
};

/** Possible values representing the state of the payment. */
export enum PaymentStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  PendingAuthorization = 'PENDING_AUTHORIZATION',
  Unknown = 'UNKNOWN'
}

/** Possible additional statuses of the payment */
export enum PaymentStatusExtended {
  Disputed = 'DISPUTED',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Refunded = 'REFUNDED'
}

/** Possible values representing the type of the payment. */
export enum PaymentType {
  CardPayment = 'CARD_PAYMENT',
  EcheckPayment = 'ECHECK_PAYMENT'
}

export type PaymentsFeatures = {
  __typename?: 'PaymentsFeatures';
  /** Flag to enable/disable the convenience fee feature for the account. */
  convenienceFees?: Maybe<Scalars['Boolean']>;
  /** Flag to enable/disable the virtual terminal in the Merchant portal. */
  virtualTerminal?: Maybe<Scalars['Boolean']>;
};

/** Value describing how frequently funds will be paid out. */
export enum PayoutInterval {
  Daily = 'DAILY',
  Manual = 'MANUAL',
  Monthly = 'MONTHLY',
  Unknown = 'UNKNOWN',
  Weekly = 'WEEKLY'
}

export type PayoutReport = {
  __typename?: 'PayoutReport';
  /** The reason why the payout report creation has failed */
  failureReason?: Maybe<ReasonInfo>;
  /** The file format of the payout report */
  format?: Maybe<FileFormat>;
  /** Unique identifier for the object */
  id: Scalars['ID'];
  /** The payout report status */
  status: ReportStatus;
  /** Publicly accessible payout report URI */
  uri?: Maybe<Scalars['ID']>;
};

export type PayoutReportItem = {
  __typename?: 'PayoutReportItem';
  /** The end date for the payout report */
  endTime: Scalars['DateTime'];
  /** The completed date for the payout report */
  payoutCompleteTime: Scalars['DateTime'];
  /** The create date for the payout report */
  payoutCreateTime: Scalars['DateTime'];
  /** The payout report id */
  payoutReportId?: Maybe<Scalars['String']>;
  /** The start date for the payout report */
  startTime: Scalars['DateTime'];
};

export type PayoutSettings = {
  __typename?: 'PayoutSettings';
  /** The last four digits of the account */
  accountLastFour?: Maybe<Scalars['String']>;
  /** The type of bank account to use for payouts */
  accountType: BankAccountType;
  /** The currency for the payouts */
  currency?: Maybe<CurrencyType>;
  /** Details on when funds from charges are available, and when they are paid out to an external account. */
  schedule?: Maybe<PaymentSchedule>;
  /** The text that appears on the bank account statement for payouts. */
  statementDescription?: Maybe<Scalars['String']>;
  /** The current status of the payout setting processing or processed */
  status: AccountPayoutsSettingsStatus;
};

/** The reason code for the pending state */
export enum PendingReasonCode {
  BankVerification = 'BANK_VERIFICATION',
  EcheckSubmission = 'ECHECK_SUBMISSION',
  PendingCapture = 'PENDING_CAPTURE',
  Processing = 'PROCESSING',
  Review = 'REVIEW',
  Unknown = 'UNKNOWN'
}

export type Person = {
  __typename?: 'Person';
  account: TenantAccount;
  /** The person’s address. */
  address?: Maybe<Address>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The person’s customer details */
  customers?: Maybe<Array<CustomerInfo>>;
  /** The person’s email address. */
  email?: Maybe<Scalars['String']>;
  /** The person’s first name. */
  firstName?: Maybe<Scalars['String']>;
  /** Unique identifier for the object */
  id: Scalars['ID'];
  /** The person’s last name. */
  lastName?: Maybe<Scalars['String']>;
  /** The owner of the person record. */
  owner: Owner;
  /** The person’s payer account details. */
  payerProfile?: Maybe<PayerProfile>;
  phone?: Maybe<Phone>;
  /** Describes the person’s relationship to the account. */
  relationship?: Maybe<AccountRelationship>;
  /** Information about the requirements for the individual, including what information needs to be collected, and by when. */
  requirements?: Maybe<AccountRequirements>;
  /** The timestamp when the person was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The user identifier who last updated the person */
  updatedBy?: Maybe<Scalars['String']>;
  /** The persons’s verification status. */
  verification?: Maybe<AccountPersonalVerification>;
};

export type Phone = {
  __typename?: 'Phone';
  countryCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  /** @deprecated deprecated, use number instead */
  phoneNumber?: Maybe<Scalars['String']>;
};

/** Input for a phone number */
export type PhoneInputType = {
  /** the phone country code. (Constraints: Pattern: ^\+?[0-9]{1,3}$) */
  countryCode: Scalars['String'];
  /** the phone number. (Constraints: Pattern: ^[0-9]{7,14}$) */
  number: Scalars['String'];
};

/** The Preview Settings */
export type PreviewSettings = {
  __typename?: 'PreviewSettings';
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
};

/** The Preview Settings */
export type PreviewSettingsInput = {
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
};

/** Input data for processing a refund */
export type ProcessRefundInput = {
  /** The amount of the refund, if partial. Otherwise null. */
  amount?: Maybe<Scalars['Int']>;
  /** The payment id */
  paymentId: Scalars['String'];
  /** The reason for the refund */
  refundReason: Scalars['String'];
};

/** Status response for mutation for creating a refund. */
export type ProcessRefundStatus = {
  __typename?: 'ProcessRefundStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** Get the details from the contextual tenant account */
  account: TenantAccount;
  /** Calculates the convenience fees for the amount provided. */
  calculateConvenienceFees: CalculatedConvenienceFees;
  /** Get details of a checkout session */
  checkoutSession: CheckoutSession;
  /** NOT ACCESSIBLE VIA THE API, queries the payers credit memo usage history */
  creditMemoHistory: CreditMemoUsageConnection;
  creditMemos: CreditMemoConnection;
  customers: CustomerConnection;
  /**
   * [NOT ACCESSIBLE BY API] Get the disputes within the filter criteria.
   *       Key Information:
   *       first = the number of items from the start cursor
   *       last = no currently supported
   *       dateRange: when using a date range the start and end dates must be in this format - "2020-01-01T00:00:00Z"
   *       edges = contain the link between 2 nodes and will only work if the filter and ordering remain consistent
   *       orderBy = if missing will default to the created time descending
   */
  disputes: DisputeConnection;
  /** [NOT ACCESSIBLE BY API] Get the integration settings for the tenant. */
  integrationSettings: TenantIntegrationSettings;
  /** Get login context */
  loginContext: LoginContext;
  payerCreditMemos: PayerCreditMemoConnection;
  /** [NOT ACCESSIBLE BY API] Get the customer for the customerID */
  payerCustomer: Customer;
  /** [NOT ACCESSIBLE BY API] This transaction summary is not accessible by the API. */
  payerTransactionSummaryByMerchant: PayerTransactionSummary;
  /** [NOT ACCESSIBLE BY API] This transaction query is not accessible by the API. */
  payerTransactions: PayerTransactionConnection;
  /** Query to return payment method requests. */
  paymentMethodRequests: PaymentMethodRequestConnection;
  paymentMethods: PaymentMethodConnection;
  /** [NOT ACCESSIBLE BY API] Get the payment request from the url token included in the email or sms communication */
  paymentRequestFromUrlToken: PaymentRequestInfo;
  /**
   * Get the  payment requests within the filter criteria.
   *     Key Information:
   *     first = the number of items from the start cursor
   *     last = no currently supported
   *     dateRange: when using a date range the start and end dates must be in this format - "2020-01-01T00:00:00Z"
   *     edges = contain the link between 2 nodes and will only work if the filter and ordering remain consistent
   *     orderBy = if missing will default to the created time descending
   *
   *     An example for a query will look like:
   *
   *     query {
   *       paymentRequests (first:10, last: 0, orderBy: { direction: DESC, field:TIMESTAMP }) {
   *           pageInfo {
   *             endCursor
   *             hasNextPage
   *             hasPreviousPage
   *             startCursor
   *           }
   *           totalCount
   *           nodes {
   *             amount
   *             communications {
   *               communicationType
   *               email
   *               phoneNumber
   *               requestTimestamp
   *             }
   *             createdAt
   *             id
   *             invoiceLink
   *             referenceNumber
   *             status
   *             statusReason
   *           }
   *           edges {
   *             cursor
   *             node {
   *               id
   *             }
   *           }
   *         }
   *       }
   */
  paymentRequests: PaymentRequestConnection;
  payments: PaymentConnection;
  /** Get the payout report from the id */
  payoutReport: PayoutReport;
  /** [NOT ACCESSIBLE BY API] Get the person from their email address */
  person: Person;
  /** [NOT ACCESSIBLE BY API] Get a list of people */
  persons: Array<Person>;
  refunds: RefundConnection;
  /** Get the report from the id */
  report: Report;
  /** Get a connection to the transaction records within the filter criteria */
  transactionRecords: TransactionRecordConnection;
  viewer: Viewer;
};


export type QueryCalculateConvenienceFeesArgs = {
  amount: Scalars['Int'];
  cardType: CardType;
  customerId?: Maybe<Scalars['String']>;
};


export type QueryCheckoutSessionArgs = {
  input: CheckoutSessionInput;
};


export type QueryCreditMemoHistoryArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<CreditMemoUsageHistoryOrder>;
  statusFilter?: Maybe<Array<CreditMemoStatus>>;
};


export type QueryCreditMemosArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  dateRangeType?: Maybe<CreditMemoDateRangeType>;
  endDate?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<CreditMemoOrder>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Array<CreditMemoStatus>>;
};


export type QueryCustomersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  dateRangeType?: Maybe<CustomerDateRangeType>;
  endDate?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<CustomerOrder>;
  queryString?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryDisputesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  dateRangeType?: Maybe<DisputeDateRangeType>;
  endDate?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy: DisputeOrder;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DisputeStatus>;
};


export type QueryPayerCreditMemosArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy: CreditMemoOrder;
  status?: Maybe<Array<CreditMemoStatus>>;
};


export type QueryPayerCustomerArgs = {
  customerId: Scalars['String'];
};


export type QueryPayerTransactionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  dateRangeType?: Maybe<PaymentDateRangeType>;
  endDate?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy: PayerTransactionOrder;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Array<PaymentRequestStatus>>;
};


export type QueryPaymentMethodRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  dateRangeType?: Maybe<DateRangeType>;
  endDate?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PaymentMethodRequestOrder>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Array<PaymentMethodRequestStatus>>;
};


export type QueryPaymentMethodsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  emailId?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PaymentMethodOrder>;
  paymentMethodType?: Maybe<PaymentMethodType>;
  queryString?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['String']>;
  status?: Maybe<Array<PaymentMethodStatus>>;
};


export type QueryPaymentRequestFromUrlTokenArgs = {
  urlToken: Scalars['String'];
};


export type QueryPaymentRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  dateRangeType?: Maybe<PaymentDateRangeType>;
  endDate?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PaymentRequestOrder>;
  overdueReminderSent?: Maybe<Scalars['Boolean']>;
  queryString?: Maybe<Scalars['String']>;
  reminderSent?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PaymentRequestStatus>;
  statusFilter?: Maybe<Array<PaymentRequestStatus>>;
};


export type QueryPaymentsArgs = {
  after?: Maybe<Scalars['String']>;
  amountRange?: Maybe<AmountRange>;
  before?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  dateRangeType?: Maybe<DateRangeType>;
  endDate?: Maybe<Scalars['DateTime']>;
  extendedPaymentStatus?: Maybe<Array<PaymentStatusExtended>>;
  first?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PaymentOrder>;
  queryString?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Array<PaymentStatus>>;
};


export type QueryPayoutReportArgs = {
  format: FileFormat;
  payoutReportId: Scalars['ID'];
};


export type QueryPersonArgs = {
  email: Scalars['String'];
};


export type QueryRefundsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  dateRangeType?: Maybe<RefundDateRangeType>;
  endDate?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<RefundOrder>;
  paymentId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Array<RefundStatus>>;
};


export type QueryReportArgs = {
  id: Scalars['String'];
};


export type QueryTransactionRecordsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy: TransactionRecordOrder;
  startDate?: Maybe<Scalars['DateTime']>;
};

/** The reason details */
export type ReasonDetail = {
  __typename?: 'ReasonDetail';
  /** The reason detail code */
  code?: Maybe<Scalars['String']>;
  /** The reason detail message */
  message?: Maybe<Scalars['String']>;
};

export type ReasonInfo = {
  __typename?: 'ReasonInfo';
  /** The top level reason code */
  code?: Maybe<Scalars['String']>;
  /** additional details */
  details?: Maybe<Array<ReasonDetail>>;
  /** The top level reason */
  message?: Maybe<Scalars['String']>;
};

export type Refund = {
  __typename?: 'Refund';
  /** The amount to be refunded */
  amount?: Maybe<Scalars['Int']>;
  /** The time the refund was attempted */
  attemptTimestamp?: Maybe<Scalars['DateTime']>;
  /** The time the refund was completed */
  completedTimestamp?: Maybe<Scalars['DateTime']>;
  /** The time the refund was created */
  createTime?: Maybe<Scalars['DateTime']>;
  /** The currency used for the refund */
  currency?: Maybe<Scalars['String']>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Details of what failed */
  failureDetail?: Maybe<ReasonInfo>;
  /** The reason why the refund failed */
  failureReason?: Maybe<Scalars['String']>;
  /** The Id for the refund */
  id?: Maybe<Scalars['String']>;
  /** The owner */
  owner: Owner;
  /** The payment */
  payment: Payment;
  /** Returns the associated payment request for the refund */
  paymentRequest?: Maybe<PaymentRequest>;
  /** The reason why the refund is pending */
  pendingReason?: Maybe<Scalars['String']>;
  /** The reason the for the refund */
  refundReason?: Maybe<Scalars['String']>;
  /** The current state of the refund */
  status?: Maybe<RefundStatus>;
};

/** A list of refunds associated with the merchant. */
export type RefundConnection = {
  __typename?: 'RefundConnection';
  /** A list of edges. */
  edges: Array<Maybe<RefundEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<Refund>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** The refund field to query by. */
export enum RefundDateRangeType {
  RefundCompleted = 'REFUND_COMPLETED',
  RefundCreated = 'REFUND_CREATED'
}

/**
 * An edge in a connection. Think of the edge as the connection between two nodes.
 *   Its tightly coupled to the filter and order by applied to the connection, any changes to the filter or order by will invalidate the edge
 */
export type RefundEdge = {
  __typename?: 'RefundEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Refund;
};

/** Ways in which lists of payments can be ordered upon return */
export type RefundOrder = {
  /** The direction in which to order payments by the specified field */
  direction: OrderDirection;
  /** The field in which to order payments */
  field: RefundOrderField;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum RefundOrderField {
  Timestamp = 'TIMESTAMP'
}

/** Possible values for the refund status */
export enum RefundStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN'
}

export type RefundsFeatures = {
  __typename?: 'RefundsFeatures';
  /** Allow merchants to process refunds from payout account. */
  skipAccountBalanceCheck: Scalars['Boolean'];
};

/** Input data for removing a customer user */
export type RemoveCustomerUserInput = {
  /** the customer user id */
  customerId: Scalars['String'];
  /** the Id of the customer user */
  email: Scalars['ID'];
};

export type Report = {
  __typename?: 'Report';
  /** The report created date */
  createdAt: Scalars['DateTime'];
  /** The report end date */
  downloadUrl?: Maybe<Scalars['String']>;
  /** The report end date */
  endDate: Scalars['DateTime'];
  /** The report expiration date */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** The reason why the report creation has failed */
  failureReason?: Maybe<ReasonInfo>;
  /** The file format of the report */
  format: FileFormat;
  /** Unique identifier for the object */
  id: Scalars['ID'];
  /** The owner of the person record. */
  owner: Owner;
  /** The file format of the report */
  reportType: ReportType;
  /** The report start date */
  startDate: Scalars['DateTime'];
  /** The report status */
  status: ReportStatus;
  /** The timestamp when the person was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** The status of the report */
export enum ReportStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN'
}

/** The type of report being generated */
export enum ReportType {
  Payment = 'PAYMENT'
}

/** Input data for resending payment receipt */
export type ResendPaymentReceiptInput = {
  /** Id of the payment */
  paymentId: Scalars['String'];
};

/** Status response for resend payment receipt mutation. */
export type ResendPaymentReceiptStatus = {
  __typename?: 'ResendPaymentReceiptStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** Input data for Level 2/Level 3 data processing. */
export type RiskMetadataPaymentInput = {
  /** The address of the payer. */
  address: AddressInputType;
  /** The line items that were paid for, if any. */
  lineItems: Array<Maybe<RiskMetadataPaymentLineItemInput>>;
  /** The phone number of the payer. */
  phone: PhoneInputType;
  /** The sales order number or other id associated with this payment. (Constraints: Minimum Length: 1, Maximum Length: 255) */
  salesOrderNumber?: Maybe<Scalars['String']>;
};

/** Input data for Level 2/Level 3 data processing. */
export type RiskMetadataPaymentLineItemInput = {
  /** The currency of the line item price. */
  currency: CurrencyType;
  /** The description of the line item. (Constraints: Maximum Length: 2047) */
  description: Scalars['String'];
  /** The price of the line item in the currency lowest denomination. */
  price: Scalars['Int'];
  /** The quantity of the line item. */
  quantity: Scalars['Int'];
};

/** The security type of email settings for tenant account. */
export enum SecurityType {
  Ssl = 'SSL',
  Tls = 'TLS'
}

/** The Send Merchant Email Options */
export type SendMerchantEmailOptions = {
  __typename?: 'SendMerchantEmailOptions';
  email?: Maybe<Scalars['String']>;
  /** Indicates if an email needs to be sent on completion. */
  sendEmailOnCompletion?: Maybe<Scalars['Boolean']>;
};

/** Input for SendMerchantEmailOptions */
export type SendMerchantEmailOptionsInput = {
  email?: Maybe<Scalars['String']>;
  /** Indicates if an email needs to be sent on completion. */
  sendEmailOnCompletion?: Maybe<Scalars['Boolean']>;
};

/** Input data for sending test email. */
export type SendTestEmailInput = {
  /** Email Id on which user want to send test email. */
  email: Scalars['String'];
};

/** Status response for sending test email. */
export type SendTestEmailStatus = {
  __typename?: 'SendTestEmailStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** Status response for mutation for upserting a statement URL. */
export type StatementAccessUrlRequestStatus = {
  __typename?: 'StatementAccessURLRequestStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  statementUrl?: Maybe<Scalars['String']>;
};

/** Payment request communication subtype */
export enum SubType {
  Forward = 'FORWARD',
  Original = 'ORIGINAL',
  Overdue = 'OVERDUE',
  Preview = 'PREVIEW',
  Reminder = 'REMINDER',
  Resend = 'RESEND',
  Unknown = 'UNKNOWN'
}

/** Input data for sending account verification document tokens. */
export type SubmitVerificationInput = {
  documentTokens: Array<DocumentTokenInput>;
};

/** Status response for mutation for upserting a tenant account. */
export type SubmitVerificationStatus = {
  __typename?: 'SubmitVerificationStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type TenantAccount = {
  __typename?: 'TenantAccount';
  /** Accepted payment methods for this tenant */
  acceptedPaymentMethods?: Maybe<Array<AcceptedPaymentMethod>>;
  /** the balance based on the currency */
  balances?: Maybe<AccountBalances>;
  /** the business profile for the tenant account */
  businessProfile?: Maybe<BusinessProfile>;
  /** the type of business */
  businessType?: Maybe<BusinessType>;
  /** The set of capabilities for this account and their associated states. Keys are names of capabilities. Values may be ACTIVE, INACTIVE, or PENDING */
  capabilities?: Maybe<TenantAccountCapabilities>;
  /** Information about the company or business. This field is null unless businessType is set to COMPANY, GOVT_ENTITY, or NON_PROFIT. */
  company?: Maybe<Company>;
  /** The account’s country */
  country?: Maybe<Scalars['String']>;
  /** The timestamp when the tenant account was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The name of the user that created the tenant account */
  createdBy?: Maybe<Scalars['String']>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Three-letter ISO currency code representing the default currency for the account. */
  defaultCurrency?: Maybe<CurrencyType>;
  /** The primary account holder’s email address */
  email?: Maybe<Scalars['String']>;
  /** Any errors that associated with this tenant account */
  errors?: Maybe<Array<Scalars['String']>>;
  /** Url of the fee schedule */
  feeSchedule?: Maybe<Scalars['String']>;
  /** Unique identifier for the object */
  id: Scalars['ID'];
  /** Summary of the merchant payer. */
  merchantSummary?: Maybe<TenantMerchantSummary>;
  /** The owner of the tenant account. */
  owner: Owner;
  /** The Aptean ERP or service that owns the tenant */
  owningProduct?: Maybe<ApteanProduct>;
  /** The payment facilitator. */
  payFacType: PayFac;
  /**
   * The tenants payer account details.
   * @deprecated Deprecated, Use paymentMethods query instead.
   */
  payerProfile?: Maybe<PayerProfile>;
  /** The ids of the payment facilitator. */
  payfac?: Maybe<PayFacIds>;
  /** A list of the payout report items for the account */
  payoutReports?: Maybe<Array<PayoutReportItem>>;
  /** Information about the requirements for the account, including what information needs to be collected, and by when. */
  requirements?: Maybe<AccountRequirements>;
  /** Options for customizing how the account functions. */
  settings?: Maybe<AccountSettings>;
  /** A list of the transaction statements for the account  */
  statements?: Maybe<Array<TransactionStatement>>;
  /** Details on acceptance of the EZPay Services Agreement. */
  tosAcceptance?: Maybe<TosAcceptance>;
  /** The timestamp when the tenant account document was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The user that last updated the tenant account document */
  updatedBy?: Maybe<Scalars['String']>;
};

export type TenantAccountCapabilities = {
  __typename?: 'TenantAccountCapabilities';
  accountPayouts: CapabilityStatus;
  achPayments: CapabilityStatus;
  cardPayments: CapabilityStatus;
};

export type TenantAccountEmailSettings = {
  __typename?: 'TenantAccountEmailSettings';
  /** Defines whether the email settings enabled or not for tenant account. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Custom text to be used within all payment requests. This can be overridden by setting the additional info on individual payment requests. */
  paymentRequestDefaultText?: Maybe<Scalars['String']>;
  /** The email settings profile for tenant account. */
  profile?: Maybe<TenantAccountEmailSettingsProfile>;
};

/** The email settings profile for tenant account. */
export type TenantAccountEmailSettingsProfile = {
  __typename?: 'TenantAccountEmailSettingsProfile';
  /** The from address of email settings for tenant account. */
  from: Scalars['String'];
  /** The host of email settings for tenant account. */
  host: Scalars['String'];
  /** The password of email settings for tenant account. */
  password: Scalars['String'];
  /** The port of email settings for tenant account. */
  port: Scalars['Int'];
  /** The security type of email settings for tenant account. */
  securityType: SecurityType;
  /** The username of email settings for tenant account. */
  username: Scalars['String'];
};

/** Input data for updating the SMTP email profile for tenant account. */
export type TenantAccountEmailSettingsProfileInput = {
  /** The default from address to send the emails. */
  from: Scalars['String'];
  /** The host to use for the SMTP service. */
  host: Scalars['String'];
  /** The host user password. */
  password: Scalars['String'];
  /** The port to use on the host. */
  port: Scalars['Int'];
  /** The security protocol of the host SMTP service. */
  securityType: SecurityType;
  /** The username for the host. */
  username: Scalars['String'];
};

export type TenantIntegrationSettings = {
  __typename?: 'TenantIntegrationSettings';
  /** List of integration settings. */
  data?: Maybe<Array<KeyValuePair>>;
  /** The owner of the tenant integration settings. */
  owner: Owner;
  /** The payment facilitator type. */
  payFacType: PayFac;
};

/** A tenants merchant summary */
export type TenantMerchantSummary = {
  __typename?: 'TenantMerchantSummary';
  /** Payments due today for the tenant */
  paymentsDueToday?: Maybe<Scalars['Int']>;
};

/** The capabilities supported by the tenant. */
export type TenantSupportedCapabilities = {
  __typename?: 'TenantSupportedCapabilities';
  /** Whether Account Balance is supported */
  accountBalance: Scalars['Boolean'];
  /** Whether Account Status is supported */
  accountStatus: Scalars['Boolean'];
  /** Whether Basic Account Status is supported */
  basicAccountStatus: Scalars['Boolean'];
  /** Whether Business Owners are supported */
  businessOwners: Scalars['Boolean'];
  /** Whether Account Description is supported */
  description: Scalars['Boolean'];
  /** Whether Disputes are supported */
  disputes: Scalars['Boolean'];
  /** Whether Entity Type is supported */
  entityType: Scalars['Boolean'];
  /** Whether Fee Schedule is supported */
  feeSchedule: Scalars['Boolean'];
  /** Whether Legal Entity Name is supported */
  legalEntityName: Scalars['Boolean'];
  /** Whether Payout Reports are supported */
  payoutReports: Scalars['Boolean'];
  /** Whether Payouts are supported */
  payouts: Scalars['Boolean'];
  /** Whether Refund Policy is supported */
  refundPolicy: Scalars['Boolean'];
  /** Whether Statement Description is supported */
  statementDescription: Scalars['Boolean'];
  /** Whether Statements are supported */
  statements: Scalars['Boolean'];
  /** Whether Website is supported */
  website: Scalars['Boolean'];
};

export type Token = {
  __typename?: 'Token';
  /** IP address of the client that generated the token. */
  clientIp?: Maybe<Scalars['String']>;
  /** Time at which the object was created. */
  createTime: Scalars['DateTime'];
  /**
   * Time at which the object was created. Measured in seconds since the Unix epoch.
   * @deprecated deprecated, use createTime instead.
   */
  created: Scalars['Float'];
  /** The credit card details. */
  creditCard?: Maybe<CreditCard>;
  /** The token id. */
  id: Scalars['String'];
  /** Indicates if the token is for the live environment. */
  livemode: Scalars['Boolean'];
  /** The status of payment method. */
  status?: Maybe<PaymentMethodStatus>;
  /** The payment method type */
  type: Scalars['String'];
  /** Indicates if the token has been used. */
  used: Scalars['Boolean'];
};

export type TosAcceptance = {
  __typename?: 'TosAcceptance';
  /** The timestamp marking when the EZPay Services Agreement was accepted by the primary account holder. */
  date?: Maybe<Scalars['DateTime']>;
  /** The IP address from which the EZPay Agreement was accepted by the account representative. */
  ip?: Maybe<Scalars['String']>;
  /** The user agent of the browser from which the EZPay Agreement was accepted by the account representative. */
  userAgent?: Maybe<Scalars['String']>;
};

export type TransactionRecord = {
  __typename?: 'TransactionRecord';
  createdAt: Scalars['DateTime'];
  /** The currency of the transaction */
  currency: CurrencyType;
  /** The fee amount applied to the transaction */
  feeAmount: Scalars['Int'];
  /** The gross amount associated with the transaction */
  grossAmount: Scalars['Int'];
  /** The id of the transaction */
  id: Scalars['String'];
  /** The net amount associated with the transaction (gross - fee = net) */
  netAmount: Scalars['Int'];
  /** The owner of the transaction. */
  owner: Owner;
  /** The type of transaction */
  transactionType: TransactionRecordType;
};

/** A list of transaction records. */
export type TransactionRecordConnection = {
  __typename?: 'TransactionRecordConnection';
  /** A list of edges. */
  edges: Array<Maybe<TransactionRecordEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<TransactionRecord>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type TransactionRecordEdge = {
  __typename?: 'TransactionRecordEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TransactionRecord;
};

/** Ways in which lists of transaction records can be ordered upon return */
export type TransactionRecordOrder = {
  /** The direction in which to order transaction records by the specified field */
  direction: OrderDirection;
  /** The field in which to order transaction records */
  field: TransactionRecordOrderField;
};

/** The field to use when sorting transaction records */
export enum TransactionRecordOrderField {
  Timestamp = 'TIMESTAMP'
}

/** The type of transaction record. */
export enum TransactionRecordType {
  Adjustment = 'ADJUSTMENT',
  AppFee = 'APP_FEE',
  AppFeeChargeback = 'APP_FEE_CHARGEBACK',
  AppFeeChargebackReversal = 'APP_FEE_CHARGEBACK_REVERSAL',
  AppFeeRefund = 'APP_FEE_REFUND',
  MerchantChargeback = 'MERCHANT_CHARGEBACK',
  MerchantChargebackFee = 'MERCHANT_CHARGEBACK_FEE',
  MerchantChargebackReversal = 'MERCHANT_CHARGEBACK_REVERSAL',
  MerchantPayment = 'MERCHANT_PAYMENT',
  MerchantPaymentRefund = 'MERCHANT_PAYMENT_REFUND',
  Payout = 'PAYOUT',
  PayoutReturn = 'PAYOUT_RETURN',
  Recovery = 'RECOVERY',
  RecoveryReturn = 'RECOVERY_RETURN',
  Unknown = 'UNKNOWN'
}

export type TransactionStatement = {
  __typename?: 'TransactionStatement';
  /** Available statement transaction formats */
  availableFormats?: Maybe<Array<TransactionStatementAvailableFormat>>;
  /** The date the statement was created */
  createdAt: Scalars['DateTime'];
  /** The end date for the statements transactions */
  endTime: Scalars['DateTime'];
  /** The start date for the statements transactions */
  startTime: Scalars['DateTime'];
  /**
   * The URL to download the statements
   * @deprecated Use the availableFormats property on this type instead.
   */
  url: Scalars['String'];
};

export type TransactionStatementAvailableFormat = {
  __typename?: 'TransactionStatementAvailableFormat';
  /** The file name of the statement */
  fileName: Scalars['String'];
  /** The file format of the statements */
  format: FileFormat;
  /** The URL to download the statements */
  url: Scalars['String'];
};

/** Values for the state of PayFac transition for a company */
export enum TransitionStatus {
  Completed = 'COMPLETED',
  None = 'NONE',
  Pending = 'PENDING',
  Requested = 'REQUESTED'
}

/** Input data for updating the convenience fees. */
export type UpdateConvenienceFeesInput = {
  /** The credit card rate in basis points. Ex: '200' for 2% */
  creditCardRateBps?: Maybe<Scalars['Int']>;
  /** The debit card rate in basis points. Ex: '200' for 2% */
  debitCardRateBps?: Maybe<Scalars['Int']>;
};

/** Status response for updating the Convenience fees. */
export type UpdateConvenienceFeesStatus = {
  __typename?: 'UpdateConvenienceFeesStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** Input data for conceding a dispute */
export type UpdateDisputeInput = {
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The document ids uploaded. (Constraints: Maximum Items: 5, Minimum Length: 1, Maximum Length: 255) */
  documents: Array<Scalars['String']>;
  /** Text explaining why they are challenging the dispute. (Constraints: Maximum Length: 65535) */
  explanation: Scalars['String'];
  /** The dispute id */
  id: Scalars['ID'];
};

/** Input data for updating the integration settings. */
export type UpdateIntegrationSettingsInput = {
  /** The integration mode */
  mode: IntegrationMode;
  /** The settings for the preview mode */
  previewSettings: PreviewSettingsInput;
  /** The flag indicates whether to process unsent payment request communications */
  processUnsentCommunications?: Maybe<Scalars['Boolean']>;
};

/** Status response for updating the integration settings. */
export type UpdateIntegrationSettingsStatus = {
  __typename?: 'UpdateIntegrationSettingsStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** Input data for updating a payment method */
export type UpdatePaymentMethodInput = {
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Indicates if this payment method is the default for the payer. */
  isDefault: Scalars['Boolean'];
  /** The payment method id */
  paymentMethodId: Scalars['ID'];
  /** The id of the person or tenant to which payment method is attached. */
  resourceId: Scalars['String'];
  /** Indicates if this payment method is shared with merchant. */
  shareWithMerchant?: Maybe<Scalars['Boolean']>;
};

/** Status response for mutation for updating a payment method. */
export type UpdatePaymentMethodStatus = {
  __typename?: 'UpdatePaymentMethodStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** Input data for updating the payment reminder settings. */
export type UpdatePaymentReminderSettingsInput = {
  /** Number of days before due date for which reminder would be sent. */
  dueDateReminderDays?: Maybe<Scalars['Int']>;
  /** Number of days after due date for which reminder would be sent. */
  overdueReminderDays?: Maybe<Scalars['Int']>;
  /** Message for reminders after due date. */
  overdueReminderMessage?: Maybe<Scalars['String']>;
  /** Message for reminders before due date. */
  reminderMessage?: Maybe<Scalars['String']>;
  /** The flag indicates whether to send reminders before due date. */
  sendDueDateReminder: Scalars['Boolean'];
  /** The flag indicates whether to send reminders after due date. */
  sendOverdueReminder: Scalars['Boolean'];
};

/** Status response for updating the Payment Reminder Settings. */
export type UpdatePaymentReminderSettingsStatus = {
  __typename?: 'UpdatePaymentReminderSettingsStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** Status response for mutation for updating payout settings. */
export type UpdatePayoutSettingsStatus = {
  __typename?: 'UpdatePayoutSettingsStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** Input data for updating the SMTP email settings for tenant account. */
export type UpdateTenantAccountEmailSettingsInput = {
  /** Defines whether the email settings enabled or not for tenant account. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Custom text to be used within all payment requests. This can be overridden by setting the additional info on individual payment requests. */
  paymentRequestDefaultText?: Maybe<Scalars['String']>;
  /** Input data for updating the SMTP email profile for tenant account. */
  profile?: Maybe<TenantAccountEmailSettingsProfileInput>;
};

/** Status response for mutation for updating email settings for tenant account. */
export type UpdateTenantAccountEmailSettingsStatus = {
  __typename?: 'UpdateTenantAccountEmailSettingsStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** Input data for updating transition status for tenant account. */
export type UpdateTenantAccountTransitionStatusInput = {
  /** The current status. */
  transitionStatus: TransitionStatus;
};


/** Input data for uploading images */
export type UploadImageInput = {
  file: Scalars['Upload'];
  imageType: ImageType;
};

/** Input data for uploading files. */
export type UploadInput = {
  file: Scalars['Upload'];
};

/** Input data for creating a credit memo */
export type UpsertCreditMemoInput = {
  /** The amount of the credit memo, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** The currency used for the credit memo */
  currency?: Maybe<CurrencyType>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The list of associated payers */
  customerAllocation?: Maybe<Array<CustomerAllocationInput>>;
  /** The Id for the credit memo */
  id?: Maybe<Scalars['String']>;
  /** The reason for the credit memo */
  reason?: Maybe<Scalars['String']>;
  /** The reference number of the credit memo */
  referenceNumber?: Maybe<Scalars['String']>;
  /** The status of the credit memo, can only be used to Close an existing credit memo. */
  status?: Maybe<CreditMemoStatus>;
  /** The current reason for the status */
  statusReason?: Maybe<Scalars['String']>;
};

/** Status response for mutation for upserting a credit memo. */
export type UpsertCreditMemoStatus = {
  __typename?: 'UpsertCreditMemoStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The credit memo */
  creditMemo?: Maybe<CreditMemo>;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** Input data for upserting a customer */
export type UpsertCustomerInput = {
  /** The customer’s primary email addresses. */
  adminEmailIds?: Maybe<Array<Scalars['String']>>;
  /** The customer conveninence fees. */
  convenienceFees?: Maybe<CustomerConvenienceFeesInput>;
  /** The customer number, must be unique for the tenant */
  customerNumber?: Maybe<Scalars['String']>;
  /** the customer id */
  id?: Maybe<Scalars['ID']>;
  /** the customer name */
  name?: Maybe<Scalars['String']>;
  /** The customer’s phone number. */
  phone?: Maybe<PhoneInputType>;
  /** Flag, whether we would like to notify new customer user with email. */
  sendEmail?: Maybe<Scalars['Boolean']>;
};

/** Status response mutation for for upserting a customer */
export type UpsertCustomerStatus = {
  __typename?: 'UpsertCustomerStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  customer?: Maybe<Customer>;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  id?: Maybe<Scalars['String']>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** Input data for upserting a customer user */
export type UpsertCustomerUserInput = {
  /** the Id of the customer */
  customerId: Scalars['String'];
  /** the users email address */
  email: Scalars['String'];
  /** the users role */
  role: CustomerRole;
  /** Flag, whether we would like to notify new customer user with email */
  sendEmail?: Maybe<Scalars['Boolean']>;
};

/** Input data for upserting a person / user for a payer account */
export type UpsertPayerInput = {
  /** the email address of the user being updated or created. Once set the email can not be changed. */
  email?: Maybe<Scalars['String']>;
  /** the first name of the user */
  firstName?: Maybe<Scalars['String']>;
  /** the users id */
  id?: Maybe<Scalars['ID']>;
  /** the last name of the user */
  lastName?: Maybe<Scalars['String']>;
  /** the phone country code */
  phoneCountryCode?: Maybe<Scalars['String']>;
  /** the phone number */
  phoneNumber?: Maybe<Scalars['String']>;
};

/** Input data for creating Payment Method Request */
export type UpsertPaymentMethodRequestInput = {
  /** ID of the customer. */
  customerId: Scalars['ID'];
  /** The Payment Method Request ID. */
  id?: Maybe<Scalars['ID']>;
  paymentAttempts?: Maybe<Array<PaymentAttemptInput>>;
  /** Textual reason the merchant is requesting new card. */
  reason?: Maybe<Scalars['String']>;
  /** Resend payment method request email */
  resendEmail?: Maybe<Scalars['Boolean']>;
  /** Restriction on request while completing. */
  restriction?: Maybe<PaymentMethodRequestRestriction>;
  sendMerchantEmailOptions?: Maybe<SendMerchantEmailOptionsInput>;
  /** Only support setting to "CLOSED" */
  status?: Maybe<PaymentMethodRequestStatus>;
  /** Only needed when closing request. */
  statusReason?: Maybe<Scalars['String']>;
  supportedPaymentMethods?: Maybe<Array<PaymentMethodType>>;
};

/** Status response for mutation for upserting a payment method request. */
export type UpsertPaymentMethodRequestStatus = {
  __typename?: 'UpsertPaymentMethodRequestStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  /** The payment method request */
  paymentMethodRequest?: Maybe<PaymentMethodRequest>;
};

/** Input data for upserting a payment request */
export type UpsertPaymentRequestInput = {
  /** Additional information for the payment request. (Constraints: Maximum Length: 400) */
  additionalInfo?: Maybe<Scalars['String']>;
  /** (Required) The amount of money to request, currency is defined in the tenant account. Required to create a payment request. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Customer ID */
  customerId?: Maybe<Scalars['String']>;
  /** Customer number */
  customerNumber?: Maybe<Scalars['String']>;
  /** Customer PO number */
  customerPONumber?: Maybe<Scalars['String']>;
  /** The amount of money to deduct from the total amount due, if paid on or before the discount end date. */
  discountAmount?: Maybe<Scalars['Int']>;
  /** The date till the discount is applicable. */
  discountEndDate?: Maybe<Scalars['DateTime']>;
  /** The payment request due date. */
  dueDate?: Maybe<Scalars['DateTime']>;
  /** (Required) The email address of the payer, destination for the email communication. Required to create a payment request. (Constraints: Maximum Length: 254) */
  email?: Maybe<Scalars['String']>;
  /** The payment request id */
  id?: Maybe<Scalars['ID']>;
  /** Invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** The reference id of the uploaded invoice */
  invoiceRef?: Maybe<Scalars['String']>;
  /** List of reference id of the uploaded invoices (Max 100 invoices allowed) */
  invoiceRefs?: Maybe<Array<Scalars['String']>>;
  /** Level 2/Level 3 data. */
  l2l3Data?: Maybe<L2L3DataInput>;
  /** Order number */
  orderNumber?: Maybe<Scalars['String']>;
  /** The phone number of the payer, destination for the sms communication. Format is E.164 format, for example +14155552671 */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Merchant-specific reference number. Required to create a payment request. (Constraints: Maximum Length: 255) */
  referenceNumber?: Maybe<Scalars['String']>;
  /** Whether to send an email or sms when updating the payment request. only applicable when updating an existing request */
  sendCommunication?: Maybe<Scalars['Boolean']>;
  /** Whether to send an email or sms when updating the payment request. only applicable when updating an existing request */
  sendManualCommunication?: Maybe<Scalars['Boolean']>;
  /** The status of the payment request, can only set to CLOSED or COMPLETED for existing payment requests */
  status?: Maybe<PaymentRequestStatus>;
  /** The reason the status is changing, required if updating the status to CLOSED or COMPLETED */
  statusReason?: Maybe<Scalars['String']>;
  /** Method of payment request. Either email and / or sms. */
  type?: Maybe<CommunicationType>;
};

/** Input data for upserting a payment request with no invoice */
export type UpsertPaymentRequestNoInvoiceInput = {
  /** Additional information for the payment request. (Constraints: Maximum Length: 400) */
  additionalInfo?: Maybe<Scalars['String']>;
  /** (Required) The amount of money to request, currency is defined in the tenant account. Required to create a payment request. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Customer ID */
  customerId?: Maybe<Scalars['String']>;
  /** Customer number */
  customerNumber?: Maybe<Scalars['String']>;
  /** Customer PO number */
  customerPONumber?: Maybe<Scalars['String']>;
  /** The amount of money to deduct from the total amount due, if paid on or before the discount end date. */
  discountAmount?: Maybe<Scalars['Int']>;
  /** The date till the discount is applicable. */
  discountEndDate?: Maybe<Scalars['DateTime']>;
  /** The payment request due date. */
  dueDate?: Maybe<Scalars['DateTime']>;
  /** (Required) The email address of the payer, destination for the email communication. Required to create a payment request. (Constraints: Maximum Length: 254) */
  email?: Maybe<Scalars['String']>;
  /** The payment request id */
  id?: Maybe<Scalars['ID']>;
  /** Invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Level 2/Level 3 data. */
  l2l3Data?: Maybe<L2L3DataInput>;
  /** Order line items */
  orderLineItems?: Maybe<Array<OrderLineItemInput>>;
  /** Order number */
  orderNumber?: Maybe<Scalars['String']>;
  /** The phone number of the payer, destination for the sms communication. Format is E.164 format, for example +14155552671 */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Merchant-specific reference number. Required to create a payment request. (Constraints: Maximum Length: 255) */
  referenceNumber?: Maybe<Scalars['String']>;
  /** Whether to send an email or sms when updating the payment request. only applicable when updating an existing request */
  sendCommunication?: Maybe<Scalars['Boolean']>;
  /** Whether to send an email or sms when updating the payment request. only applicable when updating an existing request */
  sendManualCommunication?: Maybe<Scalars['Boolean']>;
  /** The status of the payment request, can only set to CLOSED or COMPLETED for existing payment requests */
  status?: Maybe<PaymentRequestStatus>;
  /** The reason the status is changing, required if updating the status to CLOSED or COMPLETED */
  statusReason?: Maybe<Scalars['String']>;
  /** Method of payment request. Either email and / or sms. */
  type?: Maybe<CommunicationType>;
};

/** Status response for mutation for upserting a payment request. */
export type UpsertPaymentRequestStatus = {
  __typename?: 'UpsertPaymentRequestStatus';
  code: MutationStatusCode;
  customerOption?: Maybe<CustomerOption>;
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  paymentRequestId?: Maybe<Scalars['ID']>;
  paymentUrl?: Maybe<Scalars['String']>;
};

/** Input data for updating the payout schedule frequency. */
export type UpsertPayoutFrequencyInput = {
  interval: PayoutInterval;
};

/** Status response for mutation for upserting a payout frequency schedule. */
export type UpsertPayoutFrequencyStatus = {
  __typename?: 'UpsertPayoutFrequencyStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Input data for creating a payout settings using a token. */
export type UpsertPayoutSettingsInput = {
  /** Input data for updating the payout schedule frequency. */
  interval?: Maybe<PayoutInterval>;
  token: Scalars['String'];
};

/** Status response for mutation for upserting a payout setting. */
export type UpsertPayoutSettingsStatus = {
  __typename?: 'UpsertPayoutSettingsStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Input data for upserting a person */
export type UpsertPersonInput = {
  /** whether to flag the user as deleted */
  deleted?: Maybe<Scalars['Boolean']>;
  /** the users email address */
  email: Scalars['String'];
  /** the users first name */
  firstName?: Maybe<Scalars['String']>;
  /** the users id */
  id?: Maybe<Scalars['ID']>;
  /** the users last name */
  lastName?: Maybe<Scalars['String']>;
  /** the users role */
  role?: Maybe<AppRole>;
};

/** Status response mutation for for upserting a person */
export type UpsertPersonStatus = {
  __typename?: 'UpsertPersonStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  person?: Maybe<Person>;
};

/** Input data for creating a tenant account. */
export type UpsertTenantAccountInput = {
  /** (Constraints: Maximum Length: 65535) */
  accountDescription?: Maybe<Scalars['String']>;
  /** (Constraints: Minimum Length: 1, Maximum Length: 255) */
  accountName?: Maybe<Scalars['String']>;
  businessUrl?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
  /** High resolution logo image url */
  highResLogoUrl?: Maybe<Scalars['String']>;
  /** The url for the merchant logo */
  logoUrl?: Maybe<Scalars['String']>;
  primaryAccountHolderEmail?: Maybe<Scalars['String']>;
  refundPolicy?: Maybe<Scalars['String']>;
  /** Indicates whether payers are required to provide a reason for partial payments. Maximum length: 200 */
  requirePartialPaymentReason?: Maybe<Scalars['Boolean']>;
  statementDescription?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  tosAcceptanceTime?: Maybe<Scalars['DateTime']>;
  tosAcceptanceUserAgent?: Maybe<Scalars['String']>;
};

/** Status response for mutation for upserting a tenant account. */
export type UpsertTenantAccountStatus = {
  __typename?: 'UpsertTenantAccountStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** Input data for verifying payment method microdeposits */
export type VerifyPaymentMethodInput = {
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  microDeposits: Array<Scalars['Int']>;
  paymentMethodId: Scalars['ID'];
};

/** Status response mutation for for verifying payment methods */
export type VerifyPaymentMethodStatus = {
  __typename?: 'VerifyPaymentMethodStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export type Viewer = {
  __typename?: 'Viewer';
  currentUser?: Maybe<Person>;
};

/** The virtual terminal mode */
export enum VirtualTerminalMode {
  Mobile = 'MOBILE',
  Web = 'WEB'
}

export type AttachPaymentMethodMutationVariables = Exact<{
  input: AttachPaymentMethodInput;
}>;


export type AttachPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { attachPaymentMethod: (
    { __typename?: 'AttachPaymentMethodStatus' }
    & Pick<AttachPaymentMethodStatus, 'code' | 'message' | 'error'>
  ) }
);

export type CompletePaymentMethodRequestMutationVariables = Exact<{
  input: CompletePaymentMethodRequestInput;
}>;


export type CompletePaymentMethodRequestMutation = (
  { __typename?: 'Mutation' }
  & { completePaymentMethodRequest: (
    { __typename?: 'CompletePaymentMethodRequestStatus' }
    & Pick<CompletePaymentMethodRequestStatus, 'code' | 'message' | 'error'>
  ) }
);

export type ConvertPayfacPaymentMethodTokenMutationVariables = Exact<{
  input: ConvertPayfacPaymentMethodTokenInput;
}>;


export type ConvertPayfacPaymentMethodTokenMutation = (
  { __typename?: 'Mutation' }
  & { convertPayfacPaymentMethodToken: (
    { __typename?: 'ConvertPayfacPaymentMethodTokenStatus' }
    & Pick<ConvertPayfacPaymentMethodTokenStatus, 'code' | 'message' | 'error'>
    & { token?: Maybe<(
      { __typename?: 'Token' }
      & Pick<Token, 'id' | 'clientIp' | 'created' | 'livemode' | 'type' | 'used'>
      & { creditCard?: Maybe<(
        { __typename?: 'CreditCard' }
        & Pick<CreditCard, 'cardBrand' | 'displayName' | 'expirationMonth' | 'expirationYear' | 'lastFour' | 'recurring'>
        & { details?: Maybe<(
          { __typename?: 'CardDetails' }
          & Pick<CardDetails, 'type'>
        )>, cardHolder?: Maybe<(
          { __typename?: 'PaymentMethodHolder' }
          & Pick<PaymentMethodHolder, 'email' | 'holderName'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'city' | 'country' | 'line1' | 'line2' | 'postalCode' | 'region'>
          )>, phone?: Maybe<(
            { __typename?: 'Phone' }
            & Pick<Phone, 'countryCode' | 'number'>
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type CreatePaymentMutationVariables = Exact<{
  input: CreatePaymentInput;
}>;


export type CreatePaymentMutation = (
  { __typename?: 'Mutation' }
  & { createPayment: (
    { __typename?: 'CreatePaymentStatus' }
    & Pick<CreatePaymentStatus, 'code' | 'message' | 'error'>
    & { payment?: Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'amount' | 'amountDisputed' | 'amountRefunded' | 'attemptTimestamp' | 'cancelReason' | 'completedTimestamp' | 'currency' | 'failureReason' | 'feeAmount' | 'id' | 'initiatedBy' | 'pendingReason' | 'pendingReasonCode' | 'status'>
    )> }
  ) }
);

export type CreatePaymentMethodMutationVariables = Exact<{
  input: CreatePaymentMethodInput;
}>;


export type CreatePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { createPaymentMethod: (
    { __typename?: 'CreatePaymentMethodStatus' }
    & Pick<CreatePaymentMethodStatus, 'code' | 'message' | 'error'>
    & { paymentMethod?: Maybe<(
      { __typename?: 'PaymentMethod' }
      & Pick<PaymentMethod, 'id' | 'createdAt' | 'createdBy'>
      & { creditCard?: Maybe<(
        { __typename?: 'CreditCard' }
        & Pick<CreditCard, 'cardBrand'>
      )> }
    )> }
  ) }
);

export type DeletePaymentMethodMutationVariables = Exact<{
  input: DeletePaymentMethodInput;
}>;


export type DeletePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { deletePaymentMethod: (
    { __typename?: 'DeletePaymentMethodStatus' }
    & Pick<DeletePaymentMethodStatus, 'code' | 'message' | 'error'>
  ) }
);

export type UpsertPayerMutationVariables = Exact<{
  input: UpsertPayerInput;
}>;


export type UpsertPayerMutation = (
  { __typename?: 'Mutation' }
  & { upsertPayer: (
    { __typename?: 'UpsertPersonStatus' }
    & Pick<UpsertPersonStatus, 'code' | 'message' | 'error'>
  ) }
);

export type CalculateConvenienceFeesQueryVariables = Exact<{
  amount: Scalars['Int'];
  cardType: CardType;
  customerId?: Maybe<Scalars['String']>;
}>;


export type CalculateConvenienceFeesQuery = (
  { __typename?: 'Query' }
  & { calculateConvenienceFees: (
    { __typename?: 'CalculatedConvenienceFees' }
    & Pick<CalculatedConvenienceFees, 'amount' | 'rateBps'>
  ) }
);

export type OpenCreditMemosQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy: CreditMemoOrder;
  status?: Maybe<Array<CreditMemoStatus> | CreditMemoStatus>;
}>;


export type OpenCreditMemosQuery = (
  { __typename?: 'Query' }
  & { payerCreditMemos: (
    { __typename?: 'PayerCreditMemoConnection' }
    & Pick<PayerCreditMemoConnection, 'totalCount' | 'totalBalance'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ), nodes: Array<Maybe<(
      { __typename?: 'CreditMemo' }
      & Pick<CreditMemo, 'id' | 'amount' | 'remainingBalance' | 'createdAt' | 'reason' | 'referenceNumber' | 'currency'>
    )>> }
  ) }
);

export type CreditMemoDetailsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy: CreditMemoOrder;
  status?: Maybe<Array<CreditMemoStatus> | CreditMemoStatus>;
}>;


export type CreditMemoDetailsQuery = (
  { __typename?: 'Query' }
  & { payerCreditMemos: (
    { __typename?: 'PayerCreditMemoConnection' }
    & Pick<PayerCreditMemoConnection, 'totalBalance'>
  ) }
);

export type CreditMemoHistoryQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy: CreditMemoUsageHistoryOrder;
  statusFilter?: Maybe<Array<CreditMemoStatus> | CreditMemoStatus>;
}>;


export type CreditMemoHistoryQuery = (
  { __typename?: 'Query' }
  & { creditMemoHistory: (
    { __typename?: 'CreditMemoUsageConnection' }
    & Pick<CreditMemoUsageConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ), nodes: Array<Maybe<(
      { __typename?: 'CreditUsageHistory' }
      & Pick<CreditUsageHistory, 'amount' | 'createdAt' | 'creditMemoReferenceNumber' | 'paymentId' | 'remainingBalance'>
      & { paymentRequestCreditAllocation?: Maybe<Array<(
        { __typename?: 'PaymentRequestCreditAllocation' }
        & Pick<PaymentRequestCreditAllocation, 'amount' | 'paymentRequestId' | 'paymentRequestReferenceNumber'>
      )>> }
    )>> }
  ) }
);

export type PayerCustomerQueryVariables = Exact<{
  customerId: Scalars['String'];
}>;


export type PayerCustomerQuery = (
  { __typename?: 'Query' }
  & { payerCustomer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name' | 'customerNumber'>
    & { customerUsers?: Maybe<Array<(
      { __typename?: 'CustomerUser' }
      & Pick<CustomerUser, 'email' | 'role'>
    )>>, payerProfile?: Maybe<(
      { __typename?: 'CustomerPayerProfile' }
      & { paymentMethods?: Maybe<Array<(
        { __typename?: 'CustomerPaymentMethod' }
        & Pick<CustomerPaymentMethod, 'isDefault' | 'shareWithMerchant'>
        & { paymentMethod?: Maybe<(
          { __typename?: 'PaymentMethod' }
          & Pick<PaymentMethod, 'id' | 'createdAt' | 'createdBy' | 'status' | 'type' | 'updatedAt' | 'updatedBy' | 'isLongLived'>
          & { creditCard?: Maybe<(
            { __typename?: 'CreditCard' }
            & Pick<CreditCard, 'cardBrand' | 'displayName' | 'expirationMonth' | 'expirationYear' | 'lastFour' | 'recurring'>
            & { details?: Maybe<(
              { __typename?: 'CardDetails' }
              & Pick<CardDetails, 'type'>
            )>, cardHolder?: Maybe<(
              { __typename?: 'PaymentMethodHolder' }
              & Pick<PaymentMethodHolder, 'email' | 'holderName'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'city' | 'country' | 'line1' | 'line2' | 'postalCode' | 'region'>
              )>, phone?: Maybe<(
                { __typename?: 'Phone' }
                & Pick<Phone, 'countryCode' | 'number'>
              )> }
            )> }
          )>, paymentBank?: Maybe<(
            { __typename?: 'PaymentBank' }
            & Pick<PaymentBank, 'accountType' | 'lastFour'>
            & { accountHolder?: Maybe<(
              { __typename?: 'PaymentMethodHolder' }
              & Pick<PaymentMethodHolder, 'email' | 'holderName'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'city' | 'country' | 'line1' | 'line2' | 'postalCode' | 'region'>
              )>, phone?: Maybe<(
                { __typename?: 'Phone' }
                & Pick<Phone, 'countryCode' | 'number'>
              )> }
            )> }
          )> }
        )> }
      )>> }
    )> }
  ) }
);

export type PayerTransactionSummaryByMerchantQueryVariables = Exact<{ [key: string]: never; }>;


export type PayerTransactionSummaryByMerchantQuery = (
  { __typename?: 'Query' }
  & { payerTransactionSummaryByMerchant: (
    { __typename?: 'PayerTransactionSummary' }
    & { merchantSummary: Array<(
      { __typename?: 'PayerMerchantSummary' }
      & Pick<PayerMerchantSummary, 'earliestCreatedDate' | 'numberOfUnpaidRequests' | 'totalDue'>
      & { merchantInfo?: Maybe<(
        { __typename?: 'MerchantInfo' }
        & Pick<MerchantInfo, 'name' | 'country' | 'defaultCurrency' | 'id' | 'refundPolicy' | 'statementDescription' | 'supportedPaymentMethods' | 'acceptedPaymentMethods' | 'logoUrl'>
        & { owner?: Maybe<(
          { __typename?: 'Owner' }
          & Pick<Owner, 'tenantId'>
        )>, features?: Maybe<(
          { __typename?: 'Features' }
          & { creditMemos: (
            { __typename?: 'CreditMemoFeatures' }
            & Pick<CreditMemoFeatures, 'enabled'>
          ), paymentRequests: (
            { __typename?: 'PaymentRequestFeatures' }
            & Pick<PaymentRequestFeatures, 'consolidatedPayment' | 'partialPayment'>
          ), payments: (
            { __typename?: 'PaymentsFeatures' }
            & Pick<PaymentsFeatures, 'convenienceFees'>
          ) }
        )>, options?: Maybe<(
          { __typename?: 'AccountOptions' }
          & { payments?: Maybe<(
            { __typename?: 'AccountPaymentOptions' }
            & Pick<AccountPaymentOptions, 'requirePartialPaymentReason'>
          )> }
        )> }
      )>, pendingPaymentMethodRequest?: Maybe<(
        { __typename?: 'PaymentMethodRequest' }
        & Pick<PaymentMethodRequest, 'id' | 'status' | 'restriction'>
        & { paymentAttempts: Array<(
          { __typename?: 'PaymentAttempt' }
          & Pick<PaymentAttempt, 'amount' | 'customerPONumber' | 'invoiceNumber' | 'orderNumber'>
          & { paymentRequest?: Maybe<(
            { __typename?: 'PaymentRequest' }
            & Pick<PaymentRequest, 'referenceNumber'>
          )>, paymentMethod?: Maybe<(
            { __typename?: 'PaymentMethod' }
            & Pick<PaymentMethod, 'id'>
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type PaymentsQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type PaymentsQuery = (
  { __typename?: 'Query' }
  & { payments: (
    { __typename?: 'PaymentConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'id' | 'status' | 'pendingReason' | 'amount' | 'amountBeforeFees' | 'amountDisputed' | 'amountRefunded' | 'attemptTimestamp' | 'completedTimestamp' | 'createdAt' | 'cancelReason' | 'currency' | 'convenienceFee' | 'creditAmount' | 'description' | 'failureReason' | 'feeAmount' | 'initiatedBy' | 'authorizationCode' | 'customerPONumber' | 'orderNumber' | 'invoiceNumber'>
      & { paymentMethod?: Maybe<(
        { __typename?: 'PaymentMethod' }
        & { creditCard?: Maybe<(
          { __typename?: 'CreditCard' }
          & Pick<CreditCard, 'cardBrand' | 'lastFour'>
          & { cardHolder?: Maybe<(
            { __typename?: 'PaymentMethodHolder' }
            & Pick<PaymentMethodHolder, 'email'>
          )> }
        )>, paymentBank?: Maybe<(
          { __typename?: 'PaymentBank' }
          & Pick<PaymentBank, 'lastFour'>
          & { accountHolder?: Maybe<(
            { __typename?: 'PaymentMethodHolder' }
            & Pick<PaymentMethodHolder, 'email'>
          )> }
        )> }
      )>, owner: (
        { __typename?: 'Owner' }
        & Pick<Owner, 'tenantId' | 'paymentRequestId' | 'customerId'>
      ), customer?: Maybe<(
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'name' | 'customerNumber'>
      )> }
    )>> }
  ) }
);

export type PaymentMethodsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { paymentMethods: (
    { __typename?: 'PaymentMethodConnection' }
    & Pick<PaymentMethodConnection, 'totalCount'>
    & { nodes: Array<Maybe<(
      { __typename?: 'PaymentMethod' }
      & Pick<PaymentMethod, 'id' | 'createdAt' | 'createdBy' | 'status' | 'type' | 'updatedAt' | 'updatedBy'>
      & { creditCard?: Maybe<(
        { __typename?: 'CreditCard' }
        & Pick<CreditCard, 'cardBrand' | 'displayName' | 'expirationMonth' | 'expirationYear' | 'lastFour' | 'recurring'>
        & { details?: Maybe<(
          { __typename?: 'CardDetails' }
          & Pick<CardDetails, 'type'>
        )>, cardHolder?: Maybe<(
          { __typename?: 'PaymentMethodHolder' }
          & Pick<PaymentMethodHolder, 'email' | 'holderName'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'city' | 'country' | 'line1' | 'line2' | 'postalCode' | 'region'>
          )>, phone?: Maybe<(
            { __typename?: 'Phone' }
            & Pick<Phone, 'countryCode' | 'number'>
          )> }
        )> }
      )> }
    )>> }
  ) }
);

export type GetPaymentRequestFromUrlTokenQueryVariables = Exact<{
  urlToken: Scalars['String'];
}>;


export type GetPaymentRequestFromUrlTokenQuery = (
  { __typename?: 'Query' }
  & { paymentRequestFromUrlToken: (
    { __typename?: 'PaymentRequestInfo' }
    & Pick<PaymentRequestInfo, 'acceptedPaymentMethods' | 'supportedPaymentMethods' | 'amount' | 'currency' | 'invoiceLink' | 'invoiceLinks' | 'merchantName' | 'merchantStatementDescription' | 'paymentRequestId' | 'referenceNumber' | 'refundPolicy' | 'status' | 'statusReason' | 'tenantId' | 'totalDue' | 'dueDate' | 'discountEndDate' | 'discountAmount' | 'customerId'>
    & { features?: Maybe<(
      { __typename?: 'Features' }
      & { payments: (
        { __typename?: 'PaymentsFeatures' }
        & Pick<PaymentsFeatures, 'convenienceFees'>
      ) }
    )> }
  ) }
);

export type GetPersonByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetPersonByEmailQuery = (
  { __typename?: 'Query' }
  & { person: (
    { __typename?: 'Person' }
    & Pick<Person, 'email' | 'firstName' | 'id' | 'lastName'>
    & { owner: (
      { __typename?: 'Owner' }
      & Pick<Owner, 'tenantId' | 'personId'>
    ), phone?: Maybe<(
      { __typename?: 'Phone' }
      & Pick<Phone, 'phoneNumber' | 'countryCode'>
    )>, payerProfile?: Maybe<(
      { __typename?: 'PayerProfile' }
      & { paymentMethods?: Maybe<Array<(
        { __typename?: 'PayerPaymentMethod' }
        & Pick<PayerPaymentMethod, 'isDefault'>
        & { paymentMethod?: Maybe<(
          { __typename?: 'PaymentMethod' }
          & Pick<PaymentMethod, 'id' | 'createdAt' | 'createdBy' | 'status' | 'type' | 'updatedAt' | 'updatedBy' | 'isLongLived'>
          & { creditCard?: Maybe<(
            { __typename?: 'CreditCard' }
            & Pick<CreditCard, 'cardBrand' | 'displayName' | 'expirationMonth' | 'expirationYear' | 'lastFour' | 'recurring'>
            & { details?: Maybe<(
              { __typename?: 'CardDetails' }
              & Pick<CardDetails, 'type'>
            )>, cardHolder?: Maybe<(
              { __typename?: 'PaymentMethodHolder' }
              & Pick<PaymentMethodHolder, 'email' | 'holderName'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'city' | 'country' | 'line1' | 'line2' | 'postalCode' | 'region'>
              )>, phone?: Maybe<(
                { __typename?: 'Phone' }
                & Pick<Phone, 'countryCode' | 'number'>
              )> }
            )> }
          )>, paymentBank?: Maybe<(
            { __typename?: 'PaymentBank' }
            & Pick<PaymentBank, 'accountType' | 'lastFour'>
            & { accountHolder?: Maybe<(
              { __typename?: 'PaymentMethodHolder' }
              & Pick<PaymentMethodHolder, 'email' | 'holderName'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'city' | 'country' | 'line1' | 'line2' | 'postalCode' | 'region'>
              )>, phone?: Maybe<(
                { __typename?: 'Phone' }
                & Pick<Phone, 'countryCode' | 'number'>
              )> }
            )> }
          )> }
        )> }
      )>> }
    )>, customers?: Maybe<Array<(
      { __typename?: 'CustomerInfo' }
      & Pick<CustomerInfo, 'role' | 'tenantId' | 'customerId'>
    )>> }
  ) }
);

export type GetTenantIntegrationSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantIntegrationSettingsQuery = (
  { __typename?: 'Query' }
  & { integrationSettings: (
    { __typename?: 'TenantIntegrationSettings' }
    & Pick<TenantIntegrationSettings, 'payFacType'>
    & { data?: Maybe<Array<(
      { __typename?: 'KeyValuePair' }
      & Pick<KeyValuePair, 'key' | 'value'>
    )>>, owner: (
      { __typename?: 'Owner' }
      & Pick<Owner, 'tenantId'>
    ) }
  ) }
);

export type PayerTransactionsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy: PayerTransactionOrder;
  status?: Maybe<Array<PaymentRequestStatus> | PaymentRequestStatus>;
}>;


export type PayerTransactionsQuery = (
  { __typename?: 'Query' }
  & { payerTransactions: (
    { __typename?: 'PayerTransactionConnection' }
    & Pick<PayerTransactionConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ), nodes: Array<Maybe<(
      { __typename?: 'PayerTransaction' }
      & Pick<PayerTransaction, 'currency' | 'amount' | 'tenantId' | 'merchantName' | 'merchantCountry' | 'paymentDate' | 'paymentId' | 'paymentRequestCreatedTimestamp' | 'paymentRequestId' | 'personId' | 'invoiceReferenceNumber' | 'status' | 'paymentUrlToken'>
      & { paymentRequest?: Maybe<(
        { __typename?: 'PaymentRequest' }
        & Pick<PaymentRequest, 'id' | 'additionalInfo' | 'amount' | 'referenceNumber' | 'invoiceLink' | 'invoiceLinks' | 'status' | 'dueDate' | 'createdAt' | 'totalDue' | 'totalPaid' | 'discountAmount' | 'discountEndDate' | 'customerPONumber' | 'invoiceNumber' | 'orderNumber'>
        & { owner: (
          { __typename?: 'Owner' }
          & Pick<Owner, 'tenantId'>
        ), payments: Array<Maybe<(
          { __typename?: 'Payment' }
          & Pick<Payment, 'id' | 'status' | 'attemptTimestamp' | 'immediateCapture' | 'createdAt' | 'currency' | 'authorizationCode' | 'amount' | 'amountBeforeFees' | 'convenienceFee' | 'amountDisputed' | 'creditAmount'>
          & { paymentMethod?: Maybe<(
            { __typename?: 'PaymentMethod' }
            & Pick<PaymentMethod, 'type'>
            & { creditCard?: Maybe<(
              { __typename?: 'CreditCard' }
              & Pick<CreditCard, 'cardBrand' | 'lastFour'>
            )>, paymentBank?: Maybe<(
              { __typename?: 'PaymentBank' }
              & Pick<PaymentBank, 'lastFour'>
            )> }
          )> }
        )>> }
      )>, payment?: Maybe<(
        { __typename?: 'Payment' }
        & Pick<Payment, 'authorizationCode' | 'status' | 'pendingReason' | 'cancelReason' | 'failureReason' | 'amount' | 'amountDisputed' | 'amountRefunded' | 'attemptTimestamp' | 'completedTimestamp' | 'creditAmount' | 'currency'>
      )> }
    )>>, edges: Array<Maybe<(
      { __typename?: 'PayerTransactionEdge' }
      & Pick<PayerTransactionEdge, 'cursor'>
      & { node: (
        { __typename?: 'PayerTransaction' }
        & Pick<PayerTransaction, 'paymentId'>
      ) }
    )>> }
  ) }
);

export type PayerTransactionsWithTokenQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy: PayerTransactionOrder;
  status?: Maybe<Array<PaymentRequestStatus> | PaymentRequestStatus>;
}>;


export type PayerTransactionsWithTokenQuery = (
  { __typename?: 'Query' }
  & { payerTransactions: (
    { __typename?: 'PayerTransactionConnection' }
    & Pick<PayerTransactionConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ), nodes: Array<Maybe<(
      { __typename?: 'PayerTransaction' }
      & Pick<PayerTransaction, 'currency' | 'amount' | 'tenantId' | 'merchantName' | 'merchantCountry' | 'paymentDate' | 'paymentId' | 'paymentRequestCreatedTimestamp' | 'paymentRequestId' | 'personId' | 'invoiceReferenceNumber' | 'status' | 'paymentUrlToken'>
      & { paymentRequest?: Maybe<(
        { __typename?: 'PaymentRequest' }
        & Pick<PaymentRequest, 'id' | 'additionalInfo' | 'amount' | 'referenceNumber' | 'invoiceLink' | 'invoiceLinks' | 'status' | 'discountAmount' | 'discountEndDate' | 'dueDate' | 'createdAt' | 'totalDue' | 'totalPaid' | 'customerPONumber' | 'invoiceNumber' | 'orderNumber'>
        & { owner: (
          { __typename?: 'Owner' }
          & Pick<Owner, 'tenantId'>
        ), payments: Array<Maybe<(
          { __typename?: 'Payment' }
          & Pick<Payment, 'id' | 'status' | 'attemptTimestamp' | 'createdAt' | 'currency' | 'authorizationCode' | 'amount' | 'amountBeforeFees' | 'convenienceFee' | 'creditAmount' | 'amountDisputed'>
          & { paymentMethod?: Maybe<(
            { __typename?: 'PaymentMethod' }
            & { creditCard?: Maybe<(
              { __typename?: 'CreditCard' }
              & Pick<CreditCard, 'cardBrand' | 'lastFour'>
            )>, paymentBank?: Maybe<(
              { __typename?: 'PaymentBank' }
              & Pick<PaymentBank, 'lastFour'>
            )> }
          )> }
        )>> }
      )>, payment?: Maybe<(
        { __typename?: 'Payment' }
        & Pick<Payment, 'authorizationCode' | 'status' | 'pendingReason' | 'cancelReason' | 'failureReason' | 'amount' | 'currency' | 'amountDisputed' | 'amountRefunded' | 'completedTimestamp'>
      )> }
    )>>, edges: Array<Maybe<(
      { __typename?: 'PayerTransactionEdge' }
      & Pick<PayerTransactionEdge, 'cursor'>
      & { node: (
        { __typename?: 'PayerTransaction' }
        & Pick<PayerTransaction, 'paymentId'>
      ) }
    )>> }
  ) }
);

export type RemoveCustomerUserMutationVariables = Exact<{
  input: RemoveCustomerUserInput;
}>;


export type RemoveCustomerUserMutation = (
  { __typename?: 'Mutation' }
  & { removeCustomerUser: (
    { __typename?: 'DeletePersonStatus' }
    & Pick<DeletePersonStatus, 'code' | 'message' | 'error'>
  ) }
);

export type UpdatePaymentMethodMutationVariables = Exact<{
  input: UpdatePaymentMethodInput;
}>;


export type UpdatePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { updatePaymentMethod: (
    { __typename?: 'UpdatePaymentMethodStatus' }
    & Pick<UpdatePaymentMethodStatus, 'code' | 'message' | 'error'>
    & { paymentMethod?: Maybe<(
      { __typename?: 'PaymentMethod' }
      & Pick<PaymentMethod, 'id' | 'createdAt' | 'createdBy'>
      & { creditCard?: Maybe<(
        { __typename?: 'CreditCard' }
        & Pick<CreditCard, 'cardBrand'>
      )>, paymentBank?: Maybe<(
        { __typename?: 'PaymentBank' }
        & Pick<PaymentBank, 'accountType'>
      )> }
    )> }
  ) }
);

export type UpsertCustomerUserMutationVariables = Exact<{
  input: UpsertCustomerUserInput;
}>;


export type UpsertCustomerUserMutation = (
  { __typename?: 'Mutation' }
  & { upsertCustomerUser: (
    { __typename?: 'UpsertPersonStatus' }
    & Pick<UpsertPersonStatus, 'code' | 'message' | 'error'>
  ) }
);

export type VerifyPaymentMethodMutationVariables = Exact<{
  input: VerifyPaymentMethodInput;
}>;


export type VerifyPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { verifyPaymentMethod: (
    { __typename?: 'VerifyPaymentMethodStatus' }
    & Pick<VerifyPaymentMethodStatus, 'code' | 'message' | 'error'>
  ) }
);


export const AttachPaymentMethodDocument = gql`
    mutation attachPaymentMethod($input: AttachPaymentMethodInput!) {
  attachPaymentMethod(input: $input) {
    code
    message
    error
  }
}
    `;
export type AttachPaymentMethodMutationFn = Apollo.MutationFunction<AttachPaymentMethodMutation, AttachPaymentMethodMutationVariables>;

/**
 * __useAttachPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAttachPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachPaymentMethodMutation, { data, loading, error }] = useAttachPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttachPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<AttachPaymentMethodMutation, AttachPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttachPaymentMethodMutation, AttachPaymentMethodMutationVariables>(AttachPaymentMethodDocument, options);
      }
export type AttachPaymentMethodMutationHookResult = ReturnType<typeof useAttachPaymentMethodMutation>;
export type AttachPaymentMethodMutationResult = Apollo.MutationResult<AttachPaymentMethodMutation>;
export type AttachPaymentMethodMutationOptions = Apollo.BaseMutationOptions<AttachPaymentMethodMutation, AttachPaymentMethodMutationVariables>;
export const CompletePaymentMethodRequestDocument = gql`
    mutation completePaymentMethodRequest($input: CompletePaymentMethodRequestInput!) {
  completePaymentMethodRequest(input: $input) {
    code
    message
    error
  }
}
    `;
export type CompletePaymentMethodRequestMutationFn = Apollo.MutationFunction<CompletePaymentMethodRequestMutation, CompletePaymentMethodRequestMutationVariables>;

/**
 * __useCompletePaymentMethodRequestMutation__
 *
 * To run a mutation, you first call `useCompletePaymentMethodRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePaymentMethodRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePaymentMethodRequestMutation, { data, loading, error }] = useCompletePaymentMethodRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompletePaymentMethodRequestMutation(baseOptions?: Apollo.MutationHookOptions<CompletePaymentMethodRequestMutation, CompletePaymentMethodRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompletePaymentMethodRequestMutation, CompletePaymentMethodRequestMutationVariables>(CompletePaymentMethodRequestDocument, options);
      }
export type CompletePaymentMethodRequestMutationHookResult = ReturnType<typeof useCompletePaymentMethodRequestMutation>;
export type CompletePaymentMethodRequestMutationResult = Apollo.MutationResult<CompletePaymentMethodRequestMutation>;
export type CompletePaymentMethodRequestMutationOptions = Apollo.BaseMutationOptions<CompletePaymentMethodRequestMutation, CompletePaymentMethodRequestMutationVariables>;
export const ConvertPayfacPaymentMethodTokenDocument = gql`
    mutation convertPayfacPaymentMethodToken($input: ConvertPayfacPaymentMethodTokenInput!) {
  convertPayfacPaymentMethodToken(input: $input) {
    code
    message
    error
    token {
      id
      clientIp
      created
      livemode
      type
      used
      creditCard {
        cardBrand
        displayName
        expirationMonth
        expirationYear
        lastFour
        recurring
        details {
          type
        }
        cardHolder {
          address {
            city
            country
            line1
            line2
            postalCode
            region
          }
          email
          holderName
          phone {
            countryCode
            number
          }
        }
      }
    }
  }
}
    `;
export type ConvertPayfacPaymentMethodTokenMutationFn = Apollo.MutationFunction<ConvertPayfacPaymentMethodTokenMutation, ConvertPayfacPaymentMethodTokenMutationVariables>;

/**
 * __useConvertPayfacPaymentMethodTokenMutation__
 *
 * To run a mutation, you first call `useConvertPayfacPaymentMethodTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertPayfacPaymentMethodTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertPayfacPaymentMethodTokenMutation, { data, loading, error }] = useConvertPayfacPaymentMethodTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConvertPayfacPaymentMethodTokenMutation(baseOptions?: Apollo.MutationHookOptions<ConvertPayfacPaymentMethodTokenMutation, ConvertPayfacPaymentMethodTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertPayfacPaymentMethodTokenMutation, ConvertPayfacPaymentMethodTokenMutationVariables>(ConvertPayfacPaymentMethodTokenDocument, options);
      }
export type ConvertPayfacPaymentMethodTokenMutationHookResult = ReturnType<typeof useConvertPayfacPaymentMethodTokenMutation>;
export type ConvertPayfacPaymentMethodTokenMutationResult = Apollo.MutationResult<ConvertPayfacPaymentMethodTokenMutation>;
export type ConvertPayfacPaymentMethodTokenMutationOptions = Apollo.BaseMutationOptions<ConvertPayfacPaymentMethodTokenMutation, ConvertPayfacPaymentMethodTokenMutationVariables>;
export const CreatePaymentDocument = gql`
    mutation createPayment($input: CreatePaymentInput!) {
  createPayment(input: $input) {
    code
    message
    error
    payment {
      amount
      amountDisputed
      amountRefunded
      attemptTimestamp
      cancelReason
      completedTimestamp
      currency
      failureReason
      feeAmount
      id
      initiatedBy
      pendingReason
      pendingReasonCode
      status
    }
  }
}
    `;
export type CreatePaymentMutationFn = Apollo.MutationFunction<CreatePaymentMutation, CreatePaymentMutationVariables>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, options);
      }
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMutation, CreatePaymentMutationVariables>;
export const CreatePaymentMethodDocument = gql`
    mutation createPaymentMethod($input: CreatePaymentMethodInput!) {
  createPaymentMethod(input: $input) {
    code
    message
    error
    paymentMethod {
      id
      creditCard {
        cardBrand
      }
      createdAt
      createdBy
    }
  }
}
    `;
export type CreatePaymentMethodMutationFn = Apollo.MutationFunction<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;

/**
 * __useCreatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMethodMutation, { data, loading, error }] = useCreatePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>(CreatePaymentMethodDocument, options);
      }
export type CreatePaymentMethodMutationHookResult = ReturnType<typeof useCreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationResult = Apollo.MutationResult<CreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;
export const DeletePaymentMethodDocument = gql`
    mutation deletePaymentMethod($input: DeletePaymentMethodInput!) {
  deletePaymentMethod(input: $input) {
    code
    message
    error
  }
}
    `;
export type DeletePaymentMethodMutationFn = Apollo.MutationFunction<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>(DeletePaymentMethodDocument, options);
      }
export type DeletePaymentMethodMutationHookResult = ReturnType<typeof useDeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationResult = Apollo.MutationResult<DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = Apollo.BaseMutationOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;
export const UpsertPayerDocument = gql`
    mutation upsertPayer($input: UpsertPayerInput!) {
  upsertPayer(input: $input) {
    code
    message
    error
  }
}
    `;
export type UpsertPayerMutationFn = Apollo.MutationFunction<UpsertPayerMutation, UpsertPayerMutationVariables>;

/**
 * __useUpsertPayerMutation__
 *
 * To run a mutation, you first call `useUpsertPayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPayerMutation, { data, loading, error }] = useUpsertPayerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertPayerMutation(baseOptions?: Apollo.MutationHookOptions<UpsertPayerMutation, UpsertPayerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertPayerMutation, UpsertPayerMutationVariables>(UpsertPayerDocument, options);
      }
export type UpsertPayerMutationHookResult = ReturnType<typeof useUpsertPayerMutation>;
export type UpsertPayerMutationResult = Apollo.MutationResult<UpsertPayerMutation>;
export type UpsertPayerMutationOptions = Apollo.BaseMutationOptions<UpsertPayerMutation, UpsertPayerMutationVariables>;
export const CalculateConvenienceFeesDocument = gql`
    query calculateConvenienceFees($amount: Int!, $cardType: CardType!, $customerId: String) {
  calculateConvenienceFees(
    amount: $amount
    cardType: $cardType
    customerId: $customerId
  ) {
    amount
    rateBps
  }
}
    `;

/**
 * __useCalculateConvenienceFeesQuery__
 *
 * To run a query within a React component, call `useCalculateConvenienceFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateConvenienceFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateConvenienceFeesQuery({
 *   variables: {
 *      amount: // value for 'amount'
 *      cardType: // value for 'cardType'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCalculateConvenienceFeesQuery(baseOptions: Apollo.QueryHookOptions<CalculateConvenienceFeesQuery, CalculateConvenienceFeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalculateConvenienceFeesQuery, CalculateConvenienceFeesQueryVariables>(CalculateConvenienceFeesDocument, options);
      }
export function useCalculateConvenienceFeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalculateConvenienceFeesQuery, CalculateConvenienceFeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalculateConvenienceFeesQuery, CalculateConvenienceFeesQueryVariables>(CalculateConvenienceFeesDocument, options);
        }
export type CalculateConvenienceFeesQueryHookResult = ReturnType<typeof useCalculateConvenienceFeesQuery>;
export type CalculateConvenienceFeesLazyQueryHookResult = ReturnType<typeof useCalculateConvenienceFeesLazyQuery>;
export type CalculateConvenienceFeesQueryResult = Apollo.QueryResult<CalculateConvenienceFeesQuery, CalculateConvenienceFeesQueryVariables>;
export const OpenCreditMemosDocument = gql`
    query openCreditMemos($after: String, $before: String, $first: Int, $last: Int, $orderBy: CreditMemoOrder!, $status: [CreditMemoStatus!]) {
  payerCreditMemos(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    status: $status
  ) {
    totalCount
    totalBalance
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    nodes {
      id
      amount
      remainingBalance
      createdAt
      reason
      referenceNumber
      currency
    }
  }
}
    `;

/**
 * __useOpenCreditMemosQuery__
 *
 * To run a query within a React component, call `useOpenCreditMemosQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenCreditMemosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenCreditMemosQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useOpenCreditMemosQuery(baseOptions: Apollo.QueryHookOptions<OpenCreditMemosQuery, OpenCreditMemosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OpenCreditMemosQuery, OpenCreditMemosQueryVariables>(OpenCreditMemosDocument, options);
      }
export function useOpenCreditMemosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpenCreditMemosQuery, OpenCreditMemosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OpenCreditMemosQuery, OpenCreditMemosQueryVariables>(OpenCreditMemosDocument, options);
        }
export type OpenCreditMemosQueryHookResult = ReturnType<typeof useOpenCreditMemosQuery>;
export type OpenCreditMemosLazyQueryHookResult = ReturnType<typeof useOpenCreditMemosLazyQuery>;
export type OpenCreditMemosQueryResult = Apollo.QueryResult<OpenCreditMemosQuery, OpenCreditMemosQueryVariables>;
export const CreditMemoDetailsDocument = gql`
    query creditMemoDetails($after: String, $before: String, $first: Int, $last: Int, $orderBy: CreditMemoOrder!, $status: [CreditMemoStatus!]) {
  payerCreditMemos(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    status: $status
  ) {
    totalBalance
  }
}
    `;

/**
 * __useCreditMemoDetailsQuery__
 *
 * To run a query within a React component, call `useCreditMemoDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditMemoDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditMemoDetailsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useCreditMemoDetailsQuery(baseOptions: Apollo.QueryHookOptions<CreditMemoDetailsQuery, CreditMemoDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreditMemoDetailsQuery, CreditMemoDetailsQueryVariables>(CreditMemoDetailsDocument, options);
      }
export function useCreditMemoDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreditMemoDetailsQuery, CreditMemoDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreditMemoDetailsQuery, CreditMemoDetailsQueryVariables>(CreditMemoDetailsDocument, options);
        }
export type CreditMemoDetailsQueryHookResult = ReturnType<typeof useCreditMemoDetailsQuery>;
export type CreditMemoDetailsLazyQueryHookResult = ReturnType<typeof useCreditMemoDetailsLazyQuery>;
export type CreditMemoDetailsQueryResult = Apollo.QueryResult<CreditMemoDetailsQuery, CreditMemoDetailsQueryVariables>;
export const CreditMemoHistoryDocument = gql`
    query creditMemoHistory($after: String, $before: String, $first: Int, $last: Int, $orderBy: CreditMemoUsageHistoryOrder!, $statusFilter: [CreditMemoStatus!]) {
  creditMemoHistory(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    statusFilter: $statusFilter
  ) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    nodes {
      amount
      createdAt
      creditMemoReferenceNumber
      paymentId
      remainingBalance
      paymentRequestCreditAllocation {
        amount
        paymentRequestId
        paymentRequestReferenceNumber
      }
    }
  }
}
    `;

/**
 * __useCreditMemoHistoryQuery__
 *
 * To run a query within a React component, call `useCreditMemoHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditMemoHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditMemoHistoryQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *      statusFilter: // value for 'statusFilter'
 *   },
 * });
 */
export function useCreditMemoHistoryQuery(baseOptions: Apollo.QueryHookOptions<CreditMemoHistoryQuery, CreditMemoHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreditMemoHistoryQuery, CreditMemoHistoryQueryVariables>(CreditMemoHistoryDocument, options);
      }
export function useCreditMemoHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreditMemoHistoryQuery, CreditMemoHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreditMemoHistoryQuery, CreditMemoHistoryQueryVariables>(CreditMemoHistoryDocument, options);
        }
export type CreditMemoHistoryQueryHookResult = ReturnType<typeof useCreditMemoHistoryQuery>;
export type CreditMemoHistoryLazyQueryHookResult = ReturnType<typeof useCreditMemoHistoryLazyQuery>;
export type CreditMemoHistoryQueryResult = Apollo.QueryResult<CreditMemoHistoryQuery, CreditMemoHistoryQueryVariables>;
export const PayerCustomerDocument = gql`
    query payerCustomer($customerId: String!) {
  payerCustomer(customerId: $customerId) {
    id
    name
    customerNumber
    customerUsers {
      email
      role
    }
    payerProfile {
      paymentMethods {
        isDefault
        shareWithMerchant
        paymentMethod {
          id
          creditCard {
            cardBrand
            displayName
            expirationMonth
            expirationYear
            lastFour
            recurring
            details {
              type
            }
            cardHolder {
              address {
                city
                country
                line1
                line2
                postalCode
                region
              }
              email
              holderName
              phone {
                countryCode
                number
              }
            }
          }
          createdAt
          createdBy
          paymentBank {
            accountType
            lastFour
            accountHolder {
              address {
                city
                country
                line1
                line2
                postalCode
                region
              }
              email
              holderName
              phone {
                countryCode
                number
              }
            }
          }
          status
          type
          updatedAt
          updatedBy
          isLongLived
        }
      }
    }
  }
}
    `;

/**
 * __usePayerCustomerQuery__
 *
 * To run a query within a React component, call `usePayerCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayerCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayerCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function usePayerCustomerQuery(baseOptions: Apollo.QueryHookOptions<PayerCustomerQuery, PayerCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayerCustomerQuery, PayerCustomerQueryVariables>(PayerCustomerDocument, options);
      }
export function usePayerCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayerCustomerQuery, PayerCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayerCustomerQuery, PayerCustomerQueryVariables>(PayerCustomerDocument, options);
        }
export type PayerCustomerQueryHookResult = ReturnType<typeof usePayerCustomerQuery>;
export type PayerCustomerLazyQueryHookResult = ReturnType<typeof usePayerCustomerLazyQuery>;
export type PayerCustomerQueryResult = Apollo.QueryResult<PayerCustomerQuery, PayerCustomerQueryVariables>;
export const PayerTransactionSummaryByMerchantDocument = gql`
    query payerTransactionSummaryByMerchant {
  payerTransactionSummaryByMerchant {
    merchantSummary {
      earliestCreatedDate
      merchantInfo {
        name
        country
        defaultCurrency
        id
        refundPolicy
        statementDescription
        supportedPaymentMethods
        acceptedPaymentMethods
        logoUrl
        owner {
          tenantId
        }
        features {
          creditMemos {
            enabled
          }
          paymentRequests {
            consolidatedPayment
            partialPayment
          }
          payments {
            convenienceFees
          }
        }
        options {
          payments {
            requirePartialPaymentReason
          }
        }
        logoUrl
      }
      numberOfUnpaidRequests
      totalDue
      pendingPaymentMethodRequest {
        id
        status
        restriction
        paymentAttempts {
          amount
          customerPONumber
          invoiceNumber
          orderNumber
          paymentRequest {
            referenceNumber
          }
          paymentMethod {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePayerTransactionSummaryByMerchantQuery__
 *
 * To run a query within a React component, call `usePayerTransactionSummaryByMerchantQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayerTransactionSummaryByMerchantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayerTransactionSummaryByMerchantQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayerTransactionSummaryByMerchantQuery(baseOptions?: Apollo.QueryHookOptions<PayerTransactionSummaryByMerchantQuery, PayerTransactionSummaryByMerchantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayerTransactionSummaryByMerchantQuery, PayerTransactionSummaryByMerchantQueryVariables>(PayerTransactionSummaryByMerchantDocument, options);
      }
export function usePayerTransactionSummaryByMerchantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayerTransactionSummaryByMerchantQuery, PayerTransactionSummaryByMerchantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayerTransactionSummaryByMerchantQuery, PayerTransactionSummaryByMerchantQueryVariables>(PayerTransactionSummaryByMerchantDocument, options);
        }
export type PayerTransactionSummaryByMerchantQueryHookResult = ReturnType<typeof usePayerTransactionSummaryByMerchantQuery>;
export type PayerTransactionSummaryByMerchantLazyQueryHookResult = ReturnType<typeof usePayerTransactionSummaryByMerchantLazyQuery>;
export type PayerTransactionSummaryByMerchantQueryResult = Apollo.QueryResult<PayerTransactionSummaryByMerchantQuery, PayerTransactionSummaryByMerchantQueryVariables>;
export const PaymentsDocument = gql`
    query payments($id: String) {
  payments(id: $id) {
    nodes {
      id
      status
      pendingReason
      amount
      amountBeforeFees
      amountDisputed
      amountRefunded
      attemptTimestamp
      completedTimestamp
      createdAt
      cancelReason
      currency
      convenienceFee
      creditAmount
      description
      failureReason
      feeAmount
      initiatedBy
      authorizationCode
      customerPONumber
      orderNumber
      invoiceNumber
      paymentMethod {
        creditCard {
          cardBrand
          lastFour
          cardHolder {
            email
          }
        }
        paymentBank {
          lastFour
          accountHolder {
            email
          }
        }
      }
      owner {
        tenantId
        paymentRequestId
        customerId
      }
      customer {
        id
        name
        customerNumber
      }
    }
  }
}
    `;

/**
 * __usePaymentsQuery__
 *
 * To run a query within a React component, call `usePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentsQuery(baseOptions?: Apollo.QueryHookOptions<PaymentsQuery, PaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentsQuery, PaymentsQueryVariables>(PaymentsDocument, options);
      }
export function usePaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentsQuery, PaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentsQuery, PaymentsQueryVariables>(PaymentsDocument, options);
        }
export type PaymentsQueryHookResult = ReturnType<typeof usePaymentsQuery>;
export type PaymentsLazyQueryHookResult = ReturnType<typeof usePaymentsLazyQuery>;
export type PaymentsQueryResult = Apollo.QueryResult<PaymentsQuery, PaymentsQueryVariables>;
export const PaymentMethodsDocument = gql`
    query paymentMethods($id: String!) {
  paymentMethods(id: $id) {
    nodes {
      id
      createdAt
      createdBy
      status
      type
      updatedAt
      updatedBy
      creditCard {
        cardBrand
        displayName
        expirationMonth
        expirationYear
        lastFour
        recurring
        details {
          type
        }
        cardHolder {
          address {
            city
            country
            line1
            line2
            postalCode
            region
          }
          email
          holderName
          phone {
            countryCode
            number
          }
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentMethodsQuery(baseOptions: Apollo.QueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
      }
export function usePaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
        }
export type PaymentMethodsQueryHookResult = ReturnType<typeof usePaymentMethodsQuery>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<typeof usePaymentMethodsLazyQuery>;
export type PaymentMethodsQueryResult = Apollo.QueryResult<PaymentMethodsQuery, PaymentMethodsQueryVariables>;
export const GetPaymentRequestFromUrlTokenDocument = gql`
    query getPaymentRequestFromUrlToken($urlToken: String!) {
  paymentRequestFromUrlToken(urlToken: $urlToken) {
    acceptedPaymentMethods
    supportedPaymentMethods
    amount
    currency
    invoiceLink
    invoiceLinks
    merchantName
    merchantStatementDescription
    paymentRequestId
    referenceNumber
    refundPolicy
    status
    statusReason
    tenantId
    supportedPaymentMethods
    totalDue
    dueDate
    discountEndDate
    discountAmount
    customerId
    features {
      payments {
        convenienceFees
      }
    }
  }
}
    `;

/**
 * __useGetPaymentRequestFromUrlTokenQuery__
 *
 * To run a query within a React component, call `useGetPaymentRequestFromUrlTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentRequestFromUrlTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentRequestFromUrlTokenQuery({
 *   variables: {
 *      urlToken: // value for 'urlToken'
 *   },
 * });
 */
export function useGetPaymentRequestFromUrlTokenQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentRequestFromUrlTokenQuery, GetPaymentRequestFromUrlTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentRequestFromUrlTokenQuery, GetPaymentRequestFromUrlTokenQueryVariables>(GetPaymentRequestFromUrlTokenDocument, options);
      }
export function useGetPaymentRequestFromUrlTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentRequestFromUrlTokenQuery, GetPaymentRequestFromUrlTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentRequestFromUrlTokenQuery, GetPaymentRequestFromUrlTokenQueryVariables>(GetPaymentRequestFromUrlTokenDocument, options);
        }
export type GetPaymentRequestFromUrlTokenQueryHookResult = ReturnType<typeof useGetPaymentRequestFromUrlTokenQuery>;
export type GetPaymentRequestFromUrlTokenLazyQueryHookResult = ReturnType<typeof useGetPaymentRequestFromUrlTokenLazyQuery>;
export type GetPaymentRequestFromUrlTokenQueryResult = Apollo.QueryResult<GetPaymentRequestFromUrlTokenQuery, GetPaymentRequestFromUrlTokenQueryVariables>;
export const GetPersonByEmailDocument = gql`
    query getPersonByEmail($email: String!) {
  person(email: $email) {
    email
    firstName
    id
    owner {
      tenantId
      personId
    }
    lastName
    phone {
      phoneNumber
      countryCode
    }
    payerProfile {
      paymentMethods {
        isDefault
        paymentMethod {
          id
          creditCard {
            cardBrand
            displayName
            expirationMonth
            expirationYear
            lastFour
            recurring
            details {
              type
            }
            cardHolder {
              address {
                city
                country
                line1
                line2
                postalCode
                region
              }
              email
              holderName
              phone {
                countryCode
                number
              }
            }
          }
          createdAt
          createdBy
          paymentBank {
            accountType
            lastFour
            accountHolder {
              address {
                city
                country
                line1
                line2
                postalCode
                region
              }
              email
              holderName
              phone {
                countryCode
                number
              }
            }
          }
          status
          type
          updatedAt
          updatedBy
          isLongLived
        }
      }
    }
    customers {
      role
      tenantId
      customerId
    }
  }
}
    `;

/**
 * __useGetPersonByEmailQuery__
 *
 * To run a query within a React component, call `useGetPersonByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetPersonByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetPersonByEmailQuery, GetPersonByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonByEmailQuery, GetPersonByEmailQueryVariables>(GetPersonByEmailDocument, options);
      }
export function useGetPersonByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonByEmailQuery, GetPersonByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonByEmailQuery, GetPersonByEmailQueryVariables>(GetPersonByEmailDocument, options);
        }
export type GetPersonByEmailQueryHookResult = ReturnType<typeof useGetPersonByEmailQuery>;
export type GetPersonByEmailLazyQueryHookResult = ReturnType<typeof useGetPersonByEmailLazyQuery>;
export type GetPersonByEmailQueryResult = Apollo.QueryResult<GetPersonByEmailQuery, GetPersonByEmailQueryVariables>;
export const GetTenantIntegrationSettingsDocument = gql`
    query getTenantIntegrationSettings {
  integrationSettings {
    payFacType
    data {
      key
      value
    }
    owner {
      tenantId
    }
  }
}
    `;

/**
 * __useGetTenantIntegrationSettingsQuery__
 *
 * To run a query within a React component, call `useGetTenantIntegrationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantIntegrationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantIntegrationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantIntegrationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantIntegrationSettingsQuery, GetTenantIntegrationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantIntegrationSettingsQuery, GetTenantIntegrationSettingsQueryVariables>(GetTenantIntegrationSettingsDocument, options);
      }
export function useGetTenantIntegrationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantIntegrationSettingsQuery, GetTenantIntegrationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantIntegrationSettingsQuery, GetTenantIntegrationSettingsQueryVariables>(GetTenantIntegrationSettingsDocument, options);
        }
export type GetTenantIntegrationSettingsQueryHookResult = ReturnType<typeof useGetTenantIntegrationSettingsQuery>;
export type GetTenantIntegrationSettingsLazyQueryHookResult = ReturnType<typeof useGetTenantIntegrationSettingsLazyQuery>;
export type GetTenantIntegrationSettingsQueryResult = Apollo.QueryResult<GetTenantIntegrationSettingsQuery, GetTenantIntegrationSettingsQueryVariables>;
export const PayerTransactionsDocument = gql`
    query payerTransactions($after: String, $before: String, $first: Int, $last: Int, $orderBy: PayerTransactionOrder!, $status: [PaymentRequestStatus!]) {
  payerTransactions(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    status: $status
  ) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    nodes {
      currency
      amount
      tenantId
      merchantName
      merchantCountry
      paymentDate
      paymentId
      paymentRequestCreatedTimestamp
      paymentRequestId
      personId
      invoiceReferenceNumber
      status
      paymentUrlToken
      paymentRequest {
        id
        additionalInfo
        amount
        referenceNumber
        invoiceLink
        invoiceLinks
        status
        dueDate
        amount
        createdAt
        totalDue
        totalPaid
        discountAmount
        discountEndDate
        customerPONumber
        invoiceNumber
        orderNumber
        owner {
          tenantId
        }
        payments {
          id
          status
          attemptTimestamp
          immediateCapture
          createdAt
          currency
          authorizationCode
          amount
          amountBeforeFees
          convenienceFee
          amountDisputed
          creditAmount
          paymentMethod {
            type
            creditCard {
              cardBrand
              lastFour
            }
            paymentBank {
              lastFour
            }
          }
        }
      }
      payment {
        authorizationCode
        status
        pendingReason
        cancelReason
        failureReason
        amount
        amountDisputed
        amountRefunded
        attemptTimestamp
        completedTimestamp
        creditAmount
        currency
      }
    }
    edges {
      cursor
      node {
        paymentId
      }
    }
  }
}
    `;

/**
 * __usePayerTransactionsQuery__
 *
 * To run a query within a React component, call `usePayerTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayerTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayerTransactionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *      status: // value for 'status'
 *   },
 * });
 */
export function usePayerTransactionsQuery(baseOptions: Apollo.QueryHookOptions<PayerTransactionsQuery, PayerTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayerTransactionsQuery, PayerTransactionsQueryVariables>(PayerTransactionsDocument, options);
      }
export function usePayerTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayerTransactionsQuery, PayerTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayerTransactionsQuery, PayerTransactionsQueryVariables>(PayerTransactionsDocument, options);
        }
export type PayerTransactionsQueryHookResult = ReturnType<typeof usePayerTransactionsQuery>;
export type PayerTransactionsLazyQueryHookResult = ReturnType<typeof usePayerTransactionsLazyQuery>;
export type PayerTransactionsQueryResult = Apollo.QueryResult<PayerTransactionsQuery, PayerTransactionsQueryVariables>;
export const PayerTransactionsWithTokenDocument = gql`
    query payerTransactionsWithToken($after: String, $before: String, $first: Int, $last: Int, $orderBy: PayerTransactionOrder!, $status: [PaymentRequestStatus!]) {
  payerTransactions(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    status: $status
  ) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    nodes {
      currency
      amount
      tenantId
      merchantName
      merchantCountry
      paymentDate
      paymentId
      paymentRequestCreatedTimestamp
      paymentRequestId
      personId
      invoiceReferenceNumber
      status
      paymentUrlToken
      paymentRequest {
        id
        additionalInfo
        amount
        referenceNumber
        invoiceLink
        invoiceLinks
        status
        discountAmount
        discountEndDate
        dueDate
        amount
        createdAt
        totalDue
        totalPaid
        customerPONumber
        invoiceNumber
        orderNumber
        owner {
          tenantId
        }
        payments {
          id
          status
          attemptTimestamp
          createdAt
          currency
          authorizationCode
          amount
          amountBeforeFees
          convenienceFee
          creditAmount
          amountDisputed
          paymentMethod {
            creditCard {
              cardBrand
              lastFour
            }
            paymentBank {
              lastFour
            }
          }
        }
      }
      payment {
        authorizationCode
        status
        pendingReason
        cancelReason
        failureReason
        amount
        currency
        amountDisputed
        amountRefunded
        completedTimestamp
      }
    }
    edges {
      cursor
      node {
        paymentId
      }
    }
  }
}
    `;

/**
 * __usePayerTransactionsWithTokenQuery__
 *
 * To run a query within a React component, call `usePayerTransactionsWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayerTransactionsWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayerTransactionsWithTokenQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *      status: // value for 'status'
 *   },
 * });
 */
export function usePayerTransactionsWithTokenQuery(baseOptions: Apollo.QueryHookOptions<PayerTransactionsWithTokenQuery, PayerTransactionsWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayerTransactionsWithTokenQuery, PayerTransactionsWithTokenQueryVariables>(PayerTransactionsWithTokenDocument, options);
      }
export function usePayerTransactionsWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayerTransactionsWithTokenQuery, PayerTransactionsWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayerTransactionsWithTokenQuery, PayerTransactionsWithTokenQueryVariables>(PayerTransactionsWithTokenDocument, options);
        }
export type PayerTransactionsWithTokenQueryHookResult = ReturnType<typeof usePayerTransactionsWithTokenQuery>;
export type PayerTransactionsWithTokenLazyQueryHookResult = ReturnType<typeof usePayerTransactionsWithTokenLazyQuery>;
export type PayerTransactionsWithTokenQueryResult = Apollo.QueryResult<PayerTransactionsWithTokenQuery, PayerTransactionsWithTokenQueryVariables>;
export const RemoveCustomerUserDocument = gql`
    mutation removeCustomerUser($input: RemoveCustomerUserInput!) {
  removeCustomerUser(input: $input) {
    code
    message
    error
  }
}
    `;
export type RemoveCustomerUserMutationFn = Apollo.MutationFunction<RemoveCustomerUserMutation, RemoveCustomerUserMutationVariables>;

/**
 * __useRemoveCustomerUserMutation__
 *
 * To run a mutation, you first call `useRemoveCustomerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCustomerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCustomerUserMutation, { data, loading, error }] = useRemoveCustomerUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCustomerUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCustomerUserMutation, RemoveCustomerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCustomerUserMutation, RemoveCustomerUserMutationVariables>(RemoveCustomerUserDocument, options);
      }
export type RemoveCustomerUserMutationHookResult = ReturnType<typeof useRemoveCustomerUserMutation>;
export type RemoveCustomerUserMutationResult = Apollo.MutationResult<RemoveCustomerUserMutation>;
export type RemoveCustomerUserMutationOptions = Apollo.BaseMutationOptions<RemoveCustomerUserMutation, RemoveCustomerUserMutationVariables>;
export const UpdatePaymentMethodDocument = gql`
    mutation updatePaymentMethod($input: UpdatePaymentMethodInput!) {
  updatePaymentMethod(input: $input) {
    code
    message
    error
    paymentMethod {
      id
      creditCard {
        cardBrand
      }
      createdAt
      createdBy
      paymentBank {
        accountType
      }
    }
  }
}
    `;
export type UpdatePaymentMethodMutationFn = Apollo.MutationFunction<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>;

/**
 * __useUpdatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMethodMutation, { data, loading, error }] = useUpdatePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>(UpdatePaymentMethodDocument, options);
      }
export type UpdatePaymentMethodMutationHookResult = ReturnType<typeof useUpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationResult = Apollo.MutationResult<UpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>;
export const UpsertCustomerUserDocument = gql`
    mutation upsertCustomerUser($input: UpsertCustomerUserInput!) {
  upsertCustomerUser(input: $input) {
    code
    message
    error
  }
}
    `;
export type UpsertCustomerUserMutationFn = Apollo.MutationFunction<UpsertCustomerUserMutation, UpsertCustomerUserMutationVariables>;

/**
 * __useUpsertCustomerUserMutation__
 *
 * To run a mutation, you first call `useUpsertCustomerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCustomerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCustomerUserMutation, { data, loading, error }] = useUpsertCustomerUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCustomerUserMutation(baseOptions?: Apollo.MutationHookOptions<UpsertCustomerUserMutation, UpsertCustomerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertCustomerUserMutation, UpsertCustomerUserMutationVariables>(UpsertCustomerUserDocument, options);
      }
export type UpsertCustomerUserMutationHookResult = ReturnType<typeof useUpsertCustomerUserMutation>;
export type UpsertCustomerUserMutationResult = Apollo.MutationResult<UpsertCustomerUserMutation>;
export type UpsertCustomerUserMutationOptions = Apollo.BaseMutationOptions<UpsertCustomerUserMutation, UpsertCustomerUserMutationVariables>;
export const VerifyPaymentMethodDocument = gql`
    mutation verifyPaymentMethod($input: VerifyPaymentMethodInput!) {
  verifyPaymentMethod(input: $input) {
    code
    message
    error
  }
}
    `;
export type VerifyPaymentMethodMutationFn = Apollo.MutationFunction<VerifyPaymentMethodMutation, VerifyPaymentMethodMutationVariables>;

/**
 * __useVerifyPaymentMethodMutation__
 *
 * To run a mutation, you first call `useVerifyPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPaymentMethodMutation, { data, loading, error }] = useVerifyPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<VerifyPaymentMethodMutation, VerifyPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyPaymentMethodMutation, VerifyPaymentMethodMutationVariables>(VerifyPaymentMethodDocument, options);
      }
export type VerifyPaymentMethodMutationHookResult = ReturnType<typeof useVerifyPaymentMethodMutation>;
export type VerifyPaymentMethodMutationResult = Apollo.MutationResult<VerifyPaymentMethodMutation>;
export type VerifyPaymentMethodMutationOptions = Apollo.BaseMutationOptions<VerifyPaymentMethodMutation, VerifyPaymentMethodMutationVariables>;